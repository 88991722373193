import React, { useState } from 'react';
import milon from '../assets/logo-milon.png';
import nanai from '../assets/logo-nanai.png';
import kyly from '../assets/logo-kyly.png';
import grafismo from '../assets/grafismo.png';
import maniaKids from '../assets/logo-mania-kids.png';

function Brands() {
  const [selectedBrand, setSelectedBrand] = useState(null);

  const brandData = {
    kyly: {
      name: 'Kyly',
      description: 'Com produtos que combinam diversão e conforto, a marca veste desde bebês até o tamanho 18, celebrando com energia e criatividade a liberdade de movimento da criança.',
      logo: kyly
    },
    milon: {
      name: 'Milon',
      description: 'A marca é conhecida pela inspiração europeia e estilo clássico. Veste desde bebês até o tamanho 14, com um mix completo de produtos. A Milon apresenta peças em malhas, tecidos, detalhes exclusivos e alto valor percebido.',
      logo: milon
    },
    nanai: {
      name: 'Nanai',
      description: 'Marca alegre, moderna e divertida para meninas atuais e cheias de personalidade. Contemporânea e alto astral, Nanai veste meninas do tamanho 1 ao 16.',
      logo: nanai
    },
    maniaKids: {
      name: 'Mania Kids',
      description: 'Para meninos e meninas que buscam estilo e conforto, com peças modernas e criativas, a marca traduz as principais tendências da moda jeans, inovando e entregando coleções versáteis e atuais.',
      logo: maniaKids
    }
  };
  
  const closeModal = () => setSelectedBrand(null);

  return (
    <div className="">
      <div className='w-3/4 mx-auto py-16 text-2xl'>
        <div className="container mx-auto text-sm xl:text-xl text-center">
          <h2 className="text-primary font-bold text-3xl xl:text-4xl">MARCAS</h2>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-12 mt-16">
          {Object.keys(brandData).map((brandKey) => (
            <div
              key={brandKey}
              className="relative flex flex-col items-center justify-start cursor-pointer"
              onClick={() => setSelectedBrand(brandData[brandKey])}
            >
              <img loading="lazy"
                className={`w-24 h-24 lg:w-32 lg:h-32 object-contain hover:scale-125 z-50 ${
                  brandKey === 'maniaKids' ? 'invert' : ''
                }`}
                alt={`Logo ${brandData[brandKey].name}`}
                src={brandData[brandKey].logo}
              />
              <img loading="lazy"
                className="w-16 lg:w-32 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                alt="Grafismo"
                src={grafismo}
              />
            </div>
          ))}
        </div>
      </div>
      {selectedBrand && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-11/12 md:w-1/2 lg:w-1/3 relative">
            <button
              className="absolute top-4 right-4 text-black text-xl"
              onClick={closeModal}
            >
              &times;
            </button>
            <div className="flex flex-col items-center">
              <img loading="lazy"
                className={`w-24 h-24 lg:w-32 lg:h-32 object-contain z-50 ${
                  selectedBrand.name === 'Mania Kids' ? 'invert' : ''
                }`}
                alt={`Logo ${selectedBrand.name}`}
                src={selectedBrand.logo}
              />
              <h3 className="text-3xl font-bold mb-4">{selectedBrand.name}</h3>
              <p className="text-lg text-center">{selectedBrand.description}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Brands;
