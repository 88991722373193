import React from 'react';

function AreaOfActivity() {
  return (
    <div className="">
      <div className='w-3/4 mx-auto py-16 text-2xl'>
        <div className="container mx-auto text-sm xl:text-xl text-center">
          <h2 className="text-primary font-bold text-3xl xl:text-4xl">ÁREA DE ATUAÇÃO</h2>
          <div className='flex justify-center mt-16'>
            <svg className='w-4 h-4 mt-0.5' viewBox="0 0 174 173" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path className='fill-secondary' d="M126.844 85.1463L142.713 132.75L152.879 92.0843C154.83 84.2803 149.396 80.0903 147.684 78.9923C145.973 77.8933 139.899 74.7023 133.619 79.7263L126.844 85.1463ZM13.4561 172.113C10.7821 172.113 8.10106 171.305 5.76506 169.676C0.838061 166.24 -1.19594 160.103 0.703064 154.406L44.8531 21.9573C49.2991 8.61828 61.2571 0.000274658 75.3161 0.000274658C89.3771 -0.000725342 101.335 8.61728 105.781 21.9573L120.092 64.8893L121.103 64.0813C132.037 55.3323 146.719 54.5663 158.505 62.1303C170.291 69.6933 175.713 83.3583 172.316 96.9433L156.653 159.597C155.19 165.447 150.159 169.532 144.133 169.764C138.103 169.99 132.779 166.308 130.872 160.588L86.7741 28.2923C84.2191 20.6303 77.3591 20.0343 75.3171 20.0353C73.2751 20.0353 66.4141 20.6303 63.8611 28.2923L26.7781 139.539L88.8011 89.4143C89.8641 88.5643 91.4481 89.0033 91.9211 90.2793L97.7971 106.121C98.0971 106.928 97.8451 107.837 97.1721 108.375L21.8431 169.146C19.3771 171.119 16.4211 172.113 13.4561 172.113Z" />
            </svg>
            <p className='text-orange mx-2 font-bold'>ONDE ATUAMOS</p>
            <svg className='w-4 h-4 mt-0.5' viewBox="0 0 174 173" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path className='fill-secondary' d="M126.844 85.1463L142.713 132.75L152.879 92.0843C154.83 84.2803 149.396 80.0903 147.684 78.9923C145.973 77.8933 139.899 74.7023 133.619 79.7263L126.844 85.1463ZM13.4561 172.113C10.7821 172.113 8.10106 171.305 5.76506 169.676C0.838061 166.24 -1.19594 160.103 0.703064 154.406L44.8531 21.9573C49.2991 8.61828 61.2571 0.000274658 75.3161 0.000274658C89.3771 -0.000725342 101.335 8.61728 105.781 21.9573L120.092 64.8893L121.103 64.0813C132.037 55.3323 146.719 54.5663 158.505 62.1303C170.291 69.6933 175.713 83.3583 172.316 96.9433L156.653 159.597C155.19 165.447 150.159 169.532 144.133 169.764C138.103 169.99 132.779 166.308 130.872 160.588L86.7741 28.2923C84.2191 20.6303 77.3591 20.0343 75.3171 20.0353C73.2751 20.0353 66.4141 20.6303 63.8611 28.2923L26.7781 139.539L88.8011 89.4143C89.8641 88.5643 91.4481 89.0033 91.9211 90.2793L97.7971 106.121C98.0971 106.928 97.8451 107.837 97.1721 108.375L21.8431 169.146C19.3771 171.119 16.4211 172.113 13.4561 172.113Z" />
            </svg>
          </div>
          <svg className='w-full md:w-3/5 mx-auto mt-8' viewBox="0 0 1001 618" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M497.199 293.099L491.543 295.78L508.539 306.277L514.387 303.665L514.499 303.615L514.621 303.622L529.736 304.508L525.17 298.021L524.833 297.542L525.359 297.284L534.384 292.865L530.266 288.87L530.261 288.866L525.773 284.626L519.877 284.512L519.659 284.508L519.514 284.346L515.47 279.829L510.094 280.945L501.456 284.056L497.439 292.855L497.364 293.021L497.199 293.099Z" className="fill-primary" stroke="white"/>
            <path d="M268.746 128.291L272.216 126.256L272.269 126.225L272.329 126.207L288.612 121.476L288.919 121.386L289.129 121.628L293.99 127.23L306.178 126.071L306.717 126.02L306.726 126.561L306.851 134.196L316.706 136.04L313.349 130.74L312.942 130.098L313.693 129.979L327.545 127.781L334.346 104.502L325.096 100.652L324.944 100.589L324.859 100.447L319.546 91.5316L311.031 89.9173L310.916 89.8956L310.823 89.8256L306.041 86.2274L298.376 90.1088L298.24 90.1777L298.089 90.159L289.484 89.0945L289.351 89.0781L289.245 88.9978L284.344 85.3098L259.43 84.8436L252.587 91.5241L252.844 101.735L255.631 108.074L263.902 111.076L264.088 111.144L264.177 111.32L268.811 120.428L268.867 120.538L268.865 120.662L268.746 128.291Z" className="fill-primary" stroke="white"/>
            <path d="M748.647 273.3L739.959 274.335L739.049 274.443L739.451 273.619L743.952 264.393L744.009 264.275L744.117 264.2L751.22 259.301L748.602 256.047L735.564 251.478L731.478 256.457L729.653 262.162L729.601 262.327L729.457 262.424L723.787 266.253L726.159 276.296L733.71 278.926L733.882 278.986L733.975 279.142L738.143 286.126L749.277 292.427L750.359 281.901L745.059 278.518L744.582 278.214L744.942 277.778L748.647 273.3Z" className="fill-primary" stroke="white"/>
            <path d="M764.035 240.358L764.033 240.348L764.031 240.338L762.553 232.525L754.638 235.509L754.492 235.564L754.34 235.526L748.84 234.146L750.949 235.963L751.143 236.131L751.12 236.386L749.418 255.467L752.168 258.886L762.359 257.964L762.368 257.964L762.376 257.963L768.006 257.642L764.035 240.358Z" className="fill-primary" stroke="white"/>
            <path d="M383.768 139.564L380.612 143.011L389.506 148.198L394.816 148.546L395.512 148.591L395.246 149.235L391.352 158.663L397.047 160.395L397.294 160.47L397.376 160.716L401.675 173.637L407.564 176.268L407.765 176.358L407.834 176.566L410.879 185.674L410.908 185.763L410.904 185.855L410.521 194.298L413.561 195.97L428.373 194.916L428.485 194.908L428.59 194.948L439.064 199.023L438.252 192.283L430.168 180.399L424.161 176.432L423.89 176.252L423.944 175.932L425.797 164.931L425.809 164.861L425.839 164.797L429.413 157.345L429.586 156.985L429.975 157.074L442.947 160.028L443.62 155.222L437.026 151.369L436.554 151.093L436.87 150.648L447.397 135.833L447.475 135.411L439.654 121.668L429.476 111.19L425.419 112.838L425.317 112.879L425.208 112.874L407.469 112.046L407.303 112.038L407.175 111.933L402.647 108.224L396.705 110.822L396.568 110.882L396.42 110.857L388.336 109.459L379.984 112.257L379.867 112.296L379.746 112.277L372.727 111.157L368.553 121.556L369.56 131.798L380.28 133.052L380.534 133.082L380.658 133.304L383.835 138.982L384.012 139.298L383.768 139.564Z" className="fill-primary" stroke="white"/>
            <path d="M164.87 205.128L164.972 205.017L165.119 204.981L175.303 202.478L174.019 182.518L169.127 178.147L164.266 180.526L166.638 187.442L166.736 187.728L166.529 187.948L160.939 193.871L158.979 205.197L158.889 205.721L158.371 205.598L154.189 204.609L150.195 209.908L152.633 215.302L158.309 212.281L164.87 205.128Z" className="fill-primary" stroke="white"/>
            <path d="M187.687 157.464L187.436 157.503L187.256 157.324L180.729 150.818L172.672 158.999L174.816 161.419L185.584 166.983L195.673 165.774L192.567 156.701L187.687 157.464Z" className="fill-primary" stroke="white"/>
            <path d="M886.52 344.644L898.864 335.888L898.99 335.799L899.143 335.796L910.425 335.57L910.303 330.156L910.299 329.965L910.423 329.82L914.502 325.037L913.093 317.94L912.973 317.333L913.591 317.343L920.315 317.446L913.962 307.948L913.793 307.696L913.939 307.429L916.969 301.911L898.59 302.136L895.569 303.336L897.102 307.128L897.231 307.446L896.98 307.68L888.599 315.52L884.158 328.592L883.38 337.629L886.52 344.644Z" className="fill-secondary" stroke="white"/>
            <path d="M833.692 299.648L833.511 299.489L833.523 299.248L833.77 294.164L824.385 290.424L815.539 290.08L811.056 294.086L810.825 294.293L810.537 294.177L802.905 291.108L797.07 297.099L797.578 306.417L805.948 316.701L806.066 316.845L806.06 317.032L805.804 325.278L820.47 335.483L832.036 338.654L832.193 338.697L832.294 338.823L838.092 346.031L853.253 335.557L853.379 330.843L853.384 330.665L853.5 330.53L860.032 322.942L861.935 312.965L852.292 305.482L842.582 307.217L842.345 307.26L842.164 307.101L833.692 299.648Z" className="fill-primary" stroke="white"/>
            <path d="M355.365 258.681L355.308 258.62L355.275 258.544L351.184 249.238L343.885 255.76L346.332 261.774L352.265 265.001L352.398 265.073L352.468 265.206L358.353 276.295L365.516 273.672L363.623 269.444L361.411 265.258L355.365 258.681Z" className="fill-primary" stroke="white"/>
            <path d="M93.8477 110.796L93.9474 110.719L94.0716 110.699L110.381 108.017L112.977 103.379L112.595 86.9927L105.527 87.3195L91.5876 93.8382L88.6219 98.6782L88.4933 98.8881L88.2484 98.9142L82.7942 99.4943L73.9301 104.626L79.8126 114.244L85.4966 117.225L93.8477 110.796Z" className="fill-primary" stroke="white"/>
            <path d="M887.286 300.358L887.462 300.273L887.649 300.33L894.894 302.526L898.112 301.248L903.446 288.694L907.447 274.625L903.828 271.93L895.91 269.761L887.088 274.746L886.843 274.885L886.597 274.747L876.999 269.373L867.471 278.923L867.333 279.061L867.138 279.069L856.018 279.542L855.94 279.546L855.865 279.525L848.312 277.466L844.791 280.504L841.857 291.444L851.044 301.317L855.587 294.843L855.726 294.646L855.966 294.631L873.043 293.566L873.412 293.543L873.542 293.889L877.717 304.97L887.286 300.358Z" className="fill-secondary" stroke="white"/>
            <path d="M508.706 307.3L508.464 307.408L508.239 307.269L490.508 296.317L484.56 300.25L488.409 306.462L488.546 306.684L488.443 306.924L483.015 319.462L482.916 319.691L482.674 319.75L473.826 321.896L474.022 324.09L489.057 328.722L489.094 328.733L489.128 328.75L498.924 333.481L498.975 333.506L499.02 333.542L513.539 345.242L525.009 336.375L525.138 336.275L525.302 336.271L534.5 336.052L530.148 325.607L529.985 325.215L530.345 324.99L537.572 320.494L539.195 311.68L530.57 305.56L514.684 304.629L508.706 307.3Z" className="fill-primary" stroke="white"/>
            <path d="M882.414 337.921L882.361 337.803L882.372 337.674L883.134 328.824L872.563 324.841L860.708 323.69L854.374 331.047L854.246 335.837L854.24 336.091L854.031 336.235L838.357 347.063L835.02 352.11L833.557 358.756L854.044 363.707L860.577 363.007L860.958 362.966L861.096 363.323L863.251 368.874L870.405 370.555L879.46 367.592L885.943 353.11L885.814 345.516L882.414 337.921Z" className="fill-secondary" stroke="white"/>
            <path d="M926.589 286.29L926.727 286.218L926.883 286.237L938.891 287.721L939.514 285.31L934.106 278.406L933.929 278.18L934.035 277.913L935.334 274.644L925.508 268.829L925.253 268.678L925.263 268.381L925.513 261.124L919.663 260.057L910.846 266.546L904.863 271.455L908.322 274.03L908.599 274.236L908.504 274.568L904.538 288.516L916.174 291.758L926.589 286.29Z" className="fill-secondary" stroke="white"/>
            <path d="M803.92 188.518L803.281 203.243L803.25 203.969L802.583 203.681L801.105 203.042L804.183 205.933L804.295 206.037L804.329 206.186L806.314 214.94L806.33 215.01L806.326 215.083L805.409 229.239L807.759 233.125L820.74 228.187L820.914 228.121L821.088 228.184L826.399 230.105L832.407 225.764L832.484 225.708L832.577 225.685L843.665 222.888L848.349 216.939L843.688 210.86L843.627 210.78L843.601 210.683L840.503 198.92L834.853 197.797L834.633 197.754L834.52 197.561L830.941 191.507L803.92 188.518Z" className="fill-primary" stroke="white"/>
            <path d="M303.489 204.778L303.342 204.752L303.234 204.649L298.743 200.426L294.581 211.147L297.88 219.766L305.091 223.044L318.664 220.272L322.636 215.647L314.703 206.758L303.489 204.778Z" className="fill-primary" stroke="white"/>
            <path d="M939.581 63.8549L940.41 66.816L940.532 67.251L940.111 67.4161L924.935 73.3779L919.196 85.0333L919.099 85.2293L918.89 85.2917L912.312 87.2509L907.192 94.9506L912.737 101.557L919.891 100.771L924.421 97.6491L924.658 97.4852L924.919 97.6092L935.113 102.459L935.267 102.533L935.344 102.685L937.197 106.352L937.202 106.361L937.206 106.371L940.127 112.786L945.983 108.424L946.203 108.261L946.459 108.358L950.381 109.844L952.746 104.348L941.998 83.2651L941.756 82.7899L942.245 82.5788L949.399 79.4966L950.64 70.4055L939.581 63.8549Z" className="fill-primary" stroke="white"/>
            <path d="M835.35 531.474L828.783 528.38L824.816 526.607L824.816 526.608L824.811 526.605L816.794 522.911L810.648 536.296L810.418 536.798L809.941 536.519L802.424 532.117L795.64 533.352L792.051 537.82L791.981 537.907L791.88 537.956L779.336 544.07L783.579 549.378L783.893 549.771L783.498 550.083L776.082 555.937L785.779 562.761L790.777 557.736L790.809 557.704L790.847 557.678L814.146 541.59L814.376 541.432L814.631 541.544L820.304 544.022L835.35 531.474Z" className="fill-secondary" stroke="white"/>
            <path d="M896.933 419.107L896.93 418.897L897.078 418.748L902.733 413.049L896.082 410.82L895.914 410.764L895.819 410.615L889.803 401.148L883.734 398.05L875.738 404.86L877.14 412.145L877.159 412.247L877.137 412.348L875.488 419.713L882.88 424.975L890.378 424.397L890.504 424.388L890.62 424.439L897.053 427.314L896.933 419.107Z" className="fill-secondary" stroke="white"/>
            <path d="M457.24 175.983L457.27 176.475L456.778 176.512L449.663 177.051L451.411 181.149L458.866 183.037L471.071 172.82L471.193 165.152L463.706 163.034L456.475 163.581L457.24 175.983Z" className="fill-primary" stroke="white"/>
            <path d="M251.09 203.874L250.983 204.15L250.691 204.19L248.212 204.531L251.092 207.581L254.723 210.378L265.778 208.334L268.218 203.536L263.203 188.597L257.671 186.809L251.09 203.874Z" className="fill-primary" stroke="white"/>
            <path d="M827.567 181.453L827.354 181.527L827.157 181.416L810.755 172.11L804.721 176.401L803.974 187.515L831.005 190.506L833.852 186.478L831.228 180.187L827.567 181.453Z" className="fill-primary" stroke="white"/>
            <path d="M749.772 272.159L749.932 272.14L750.073 272.218L752.682 273.662L765.23 270.635L762.036 258.997L752.124 259.893L744.795 264.949L740.754 273.233L749.772 272.159Z" className="fill-primary" stroke="white"/>
            <path d="M799.159 242.485L799.088 242.457L799.028 242.409L792.629 237.275L783.535 235.1L776.03 239.493L775.937 239.548L775.83 239.559L765.139 240.683L769.037 257.646L785.67 259.361L796.967 249.266L800.723 243.096L799.159 242.485Z" className="fill-primary" stroke="white"/>
            <path d="M819.517 544.77L814.488 542.573L791.455 558.476L786.598 563.358L794.793 569.496L819.517 544.77Z" className="fill-secondary" stroke="white"/>
            <path d="M960.005 188.459L959.676 188.429L959.574 188.115L957.067 180.386L948.466 182.308L958.113 190.055L958.2 190.125L958.249 190.226L960.83 195.53L964.276 197.516L967.18 189.111L960.005 188.459Z" className="fill-secondary" stroke="white"/>
            <path d="M950.35 79.9117L950.312 80.1915L950.052 80.3032L943.129 83.2861L953.33 103.295L962.906 87.169L957.593 84.7958L957.241 84.6384L957.304 84.2578L958.451 77.3133L951.546 71.1439L950.35 79.9117Z" className="fill-primary" stroke="white"/>
            <path d="M778.154 574.012L778.097 574.085L778.018 574.132L769.61 579.18L759.777 590.107L771.156 595.682L794.1 570.227L785.927 564.106L778.154 574.012Z" className="fill-secondary" stroke="white"/>
            <path d="M35.7364 153.287L35.8852 153.033L36.1794 153.04L46.0534 153.272L49.2826 150.6L47.6506 130.858L40.0066 135.629L39.9056 135.692L39.7871 135.702L32.1599 136.396L26.9811 141.701L26.3592 147.146L33.2298 157.562L35.7364 153.287Z" className="fill-primary" stroke="white"/>
            <path d="M75.755 120.479L75.6888 120.568L75.5908 120.621L67.4596 125.025L80.0192 136.928L84.7874 139.591L86.6245 135.59L85.072 118.132L79.6037 115.264L75.755 120.479Z" className="fill-primary" stroke="white"/>
            <path d="M939.471 113.763L937.182 108.736L936.851 119.486L936.849 119.568L936.82 119.645L934.834 124.969L934.762 125.162L934.575 125.248L926.136 129.132L926.248 134.014L930.834 133.174L931.206 133.105L931.373 133.444L934.099 138.982L947.05 135.199L945.917 126.991L945.904 126.894L945.928 126.798L950.053 110.789L946.359 109.389L940.225 113.957L939.728 114.327L939.471 113.763Z" className="fill-secondary" stroke="white"/>
            <path d="M628.34 73.4249L628.304 73.2233L628.42 73.0548L632.556 67.0498L631.785 57.2879L626.251 47.9727L621.56 46.5388L608.374 51.0439L604.6 56.89L605.498 62.8543L605.523 63.0193L605.444 63.1661L600.251 72.7961L606.671 92.5835L606.677 92.6032L606.682 92.6234L609.443 104.373L612.842 109.863L612.999 110.116L612.851 110.375L610.005 115.35L616.343 121.122L626.236 116.362L632.31 106.75L622.902 96.386L622.693 96.1553L622.808 95.8655L629.374 79.3181L628.34 73.4249Z" className="fill-primary" stroke="white"/>
            <path d="M42.571 165.465L42.6673 165.448L42.7627 165.469L51.816 167.463L54.5559 167.157L56.7584 157.205L46.1557 154.272L36.4513 154.044L33.7619 158.631L34.1276 166.907L42.571 165.465Z" className="fill-primary" stroke="white"/>
            <path d="M815.055 163.106L815.098 163.032L815.163 162.977L828.471 151.669L827.569 140.735L826.56 137.13L816.03 127.256L801.331 123.258L800.538 124.942L802.796 131.444L807.546 134.427L807.807 134.591L807.777 134.899L806.851 144.362L806.822 144.653L806.555 144.77L797.03 148.968L789.4 160.985L790.267 166.958L804.215 175.534L810.344 171.175L815.055 163.106Z" className="fill-primary" stroke="white"/>
            <path d="M956.759 261.037L956.63 260.888L956.637 260.691L957.133 247.31L951.177 250.963L951.097 251.011L951.006 251.028L943.328 252.447L943.143 252.482L942.981 252.385L938.776 249.88L927.302 252.069L926.238 252.914L920.518 259.196L926.118 260.217L926.543 260.295L926.528 260.726L926.273 268.119L936.211 274.001L936.579 274.218L936.421 274.616L935.071 278.015L940.239 284.612L947.982 282.117L948.214 282.042L948.416 282.179L953.636 285.706L966.382 281.308L964.87 270.388L956.759 261.037Z" className="fill-secondary" stroke="white"/>
            <path d="M882.385 145.847L882.279 145.742L882.249 145.595L879.866 134.003L879.812 133.74L880.002 133.549L884.078 129.466L880.124 123.685L870.777 125.287L870.326 125.364L870.209 124.922L868.233 117.443L866.022 120.587L865.506 131.085L869.559 139.964L869.626 140.11L869.595 140.267L866.418 156.591L866.342 156.977L865.95 156.995L854.068 157.542L860.498 165.89L860.607 166.031L860.602 166.209L860.079 185.03L864.681 189.371L864.816 189.498L864.835 189.682L865.895 199.499L865.91 199.638L865.85 199.765L861.647 208.756L864.003 210.67L878.4 203.27V198.254V198.252L878.268 178.738L878.266 178.536L878.406 178.389L886.394 170.005L880.946 165.027L880.754 164.851L880.788 164.593L881.702 157.585L879.604 148.565L879.516 148.184L879.864 148.007L882.96 146.426L882.385 145.847Z" className="fill-secondary" stroke="white"/>
            <path d="M722.941 341.238L723.202 341.231L723.357 341.442L730.379 351.024L740.843 345.279L746.464 333.834L748.242 329.975L746.198 321.417L735.61 311.494L730.001 311.836L729.799 311.848L729.646 311.718L724.106 306.997L711.597 307.696L711.445 307.705L711.314 307.627L706.607 304.822L702.787 312.198L701.078 325.478L701.046 325.725L700.828 325.849L692.854 330.373L694.419 334.768L706.036 332.259L706.332 332.195L706.526 332.428L714.049 341.468L722.941 341.238Z" className="fill-primary" stroke="white"/>
            <path d="M973.728 218.951L973.64 219.117L973.464 219.184L967.526 221.441L967.169 224.561L972.845 220.098L972.991 219.983L973.177 219.991L978.126 220.218L983.633 214.969L979.412 208.241L973.728 218.951Z" className="fill-secondary" stroke="white"/>
            <path d="M746.227 448.909L742.253 447.551L740.449 455.504L744.741 460.107L752.316 459.648L752.819 459.617L752.845 460.12L753.234 467.528L760.148 476.037L760.241 476.151L760.257 476.298L761.106 484.11L763.595 482.906L770.495 477.425L769.983 469.88L769.962 469.561L770.242 469.408L784.727 461.478L785.876 455.206L785.901 455.069L785.994 454.965L790.634 449.728L785.307 446.375L785.016 446.192L785.083 445.855L786.671 437.811L786.686 437.736L786.722 437.669L793.116 425.894L789.854 412.953L789.824 412.833L789.853 412.713L791.971 403.96L792.001 403.837L792.084 403.744L801.399 393.34H789.956L780.151 399.648L780.019 399.733L779.863 399.727L773.442 399.497L767.521 404.676L766.876 412.164L766.829 412.711L766.289 412.613L757.374 410.996L751.675 415.41L748.099 421.915L749.796 433.816L749.808 433.9L749.791 433.984L746.879 448.534L746.767 449.093L746.227 448.909Z" className="fill-primary" stroke="white"/>
            <path d="M273.57 143.133L273.541 143.018L273.567 142.902L274.994 136.526L272.176 127.437L268.566 129.554L263.019 136.701L262.159 144.163L274.561 147.068L273.57 143.133Z" className="fill-primary" stroke="white"/>
            <path d="M142.926 126.856L142.856 126.66L142.946 126.473L144.941 122.277L136.416 119.882L133.005 121.358L137.204 128.2L137.336 128.414L137.242 128.648L134.316 135.923L137.372 139.178L144.139 138.518L145.897 135.166L142.926 126.856Z" className="fill-primary" stroke="white"/>
            <path d="M772.654 307.985L774.817 301.511L768.387 306.068L768.28 306.144L768.15 306.157L755.73 307.456L736.846 311.281L746.994 320.791L747.103 320.894L747.138 321.04L749.257 329.911L749.297 330.079L749.225 330.237L747.538 333.896L754.621 339.337L763.571 338.873L763.809 338.861L763.968 339.038L771.59 347.492L778.331 351.198L804.199 356.152L804.271 356.165L804.336 356.199L812.994 360.702L834.217 351.511L837.376 346.735L831.617 339.575L820.123 336.424L820.041 336.401L819.97 336.352L805.011 325.943L804.788 325.788L804.797 325.517L805.056 317.186L796.701 306.922L796.598 306.796L796.59 306.633L796.096 297.585L787.762 300.323L773.377 308.578L772.238 309.231L772.654 307.985Z" className="fill-primary" stroke="white"/>
            <path d="M863.853 211.757L863.727 211.734L863.627 211.653L861.003 209.522L851.326 216.062L854.739 224.081L854.878 224.407L854.616 224.646L849.118 229.676L844.561 238.045L849.002 242.979L866.827 236.934L866.895 236.911L866.968 236.908L882.277 236.323L885.332 233.593L887.52 223.818L885.925 218.115L882.423 221.446L882.216 221.643L881.94 221.564L874.527 219.435L874.348 219.383L874.246 219.227L870.128 212.902L863.853 211.757Z" className="fill-secondary" stroke="white"/>
            <path d="M866.773 427.744L866.951 427.815L867.035 427.986L870.053 434.077L874.444 419.8L876.138 412.231L874.746 404.997L865.51 400.068L853.756 398.756L855.264 402.222L855.326 402.363L855.297 402.514L853.576 411.622L853.517 411.934L853.21 412.014L841.187 415.126L846.958 416.243L847.11 416.273L847.219 416.383L852.97 422.223L866.773 427.744Z" className="fill-secondary" stroke="white"/>
            <path d="M729.225 251.255L729.152 250.973L729.36 250.771L733.25 246.979L727.285 243.635L719.349 246.627L719.221 254.804L719.219 254.973L719.113 255.106L714.962 260.344L723.211 265.438L728.756 261.694L730.513 256.201L729.225 251.255Z" className="fill-primary" stroke="white"/>
            <path d="M540.956 256.307L541.356 256.325L541.425 256.72L542.441 262.503L555.797 269.108L561.942 270.565L567.816 264.087L565.725 243.067L563.532 236.153L556.089 229.614L550.311 232.756L550.306 232.759L535.964 240.798L534.941 247.77L534.888 248.131L534.528 248.191L529.366 249.049L531.005 255.851L540.956 256.307Z" className="fill-primary" stroke="white"/>
            <path d="M914.117 195.368L914.025 195.251L914.012 195.104L913.407 188.407L901.703 192.481L899.113 204.743L899.083 204.888L898.979 204.992L886.74 217.324L888.515 223.668L888.549 223.79L888.521 223.912L886.446 233.184L891.005 232.091L908.404 220.313L908.531 220.227H908.685H910.775L923.274 211.269L927.3 204.947L917.717 199.877L917.624 199.828L917.558 199.744L914.117 195.368Z" className="fill-secondary" stroke="white"/>
            <path d="M480.624 126.944L480.265 126.888L480.207 126.529L479.131 119.799L471.687 124.161L471.551 124.241L471.394 124.228L459.847 123.29L452.094 125.902L448.487 135.457L448.432 135.752L453.93 138.481L461.209 138.017L461.338 138.009L461.454 138.063L476.81 145.279L476.991 145.364L477.063 145.551L480.505 154.423L480.648 154.789L480.325 155.014L473.301 159.895L471.835 163.622L477.981 165.417L487.723 159.461L489.116 156.407L489.26 156.091L489.606 156.116L514.229 157.89L514.534 157.912L514.653 158.194L516.384 162.28L526.527 162.739L526.861 162.754L526.975 163.068L528.422 167.056L551.176 163.38L559.202 145.052L550.538 141.462L550.464 141.431L550.404 141.379L539.549 132.034L539.495 131.988L539.457 131.929L536.39 127.248L524.248 124.507L514.244 132.634L514.127 132.729L513.978 132.744L499.814 134.163L499.627 134.182L499.475 134.074L491.892 128.701L480.624 126.944Z" className="fill-primary" stroke="white"/>
            <path d="M97.1499 203.692L97.1376 203.705L97.1245 203.716L93.1362 207.178L102.934 210.702L114.37 202.09L121.588 178.479L105.13 173.615L104.407 174.261L102.472 184.982L105.223 195.284L105.297 195.563L105.093 195.767L97.1499 203.692Z" className="fill-primary" stroke="white"/>
            <path d="M553.804 131.459L553.792 131.302L553.871 131.166L558.1 123.952L549.348 107.161L533.725 105.3L533.577 105.282L533.464 105.187L521.362 95.0711L509.743 98.7221L504.413 106.621L504.311 106.772L504.135 106.822L498.621 108.39L497.742 114.34L497.672 114.814L497.195 114.764L482.976 113.262L480.027 119.079L481.136 126.012L492.161 127.731L492.277 127.749L492.373 127.817L499.899 133.15L513.728 131.764L523.805 123.578L523.993 123.425L524.23 123.479L536.807 126.318L537.004 126.362L537.115 126.531L540.253 131.322L550.994 140.569L558.629 143.733L554.274 136.649L554.211 136.546L554.201 136.427L553.804 131.459Z" className="fill-primary" stroke="white"/>
            <path d="M947.517 205.231L947.571 205.009L947.775 204.905L959.339 198.983L959.923 195.956L957.398 190.765L947.39 182.728L940.558 190.076L940.47 190.171L940.346 190.211L935.769 191.688L928.451 204.999L928.444 205.013L928.435 205.027L924.236 211.621L926.281 214.869L945.746 212.466L947.517 205.231Z" className="fill-secondary" stroke="white"/>
            <path d="M579.242 418.753L579.269 418.764L579.295 418.777L586.826 422.838L591.98 422.051L610.937 409.669L612.556 404.66L609.819 395.225L609.739 394.95L609.936 394.742L619.335 384.806L619.468 384.666L619.66 384.651L624.099 384.311L634.125 373.561L630.306 371.39L629.738 371.067L630.199 370.603L637.379 363.37L638.772 357.255L635.982 348.413L620.73 344.024L606.812 346.488L606.629 346.521L606.469 346.425L595.806 340.074L588.576 354.107L592.307 356.58L592.688 356.832L592.471 357.234L586.911 367.526L586.798 367.734L586.565 367.779L576.271 369.759L570.823 375.786L570.714 375.907L570.553 375.94L559.036 378.305L558.979 378.317L558.922 378.316L546.4 377.97L542.452 385.881L546.084 386.794L546.335 386.857L546.429 387.098L549.479 394.934L560.911 394.705L561.203 394.699L561.351 394.95L567.441 405.241L567.481 405.308L567.498 405.384L569.69 414.946L579.242 418.753Z" className="fill-primary" stroke="white"/>
            <path d="M879.33 9.28942L879.373 8.88256L879.78 8.84425L897.007 7.22376L897.783 2.76391L895.269 2.29601L885.895 7.53096L885.719 7.62961L885.523 7.57767L875.788 4.99174L858.751 4.87472L846.779 10.2232L834.808 24.9173L834.73 25.0132L834.616 25.0616L823.585 29.7553L820.079 33.2366L819.986 33.3289L819.86 33.3637L811.855 35.5726L818.133 40.1628L826.268 38.8935L826.598 38.8419L826.772 39.1277L830.021 44.4689L843.091 52.7129L851.944 44.1154L851.944 44.1154L851.951 44.1083L857.901 38.5653L854.226 31.0915L854.07 30.7739L854.317 30.521L859.878 24.8431L859.943 24.7769L860.027 24.7383L875.78 17.5226L875.889 17.4728L876.008 17.4776L878.452 17.5768L879.33 9.28942Z" className="fill-primary" stroke="white"/>
            <path d="M590.533 330.974L590.49 330.893L590.478 330.802L588.799 317.994L580.373 315.484L580.27 315.453L580.189 315.383L567.29 304.21L556.793 300.692L556.729 300.671L556.674 300.634L550.006 296.166L546.712 297.441L551.738 313.793L551.774 313.91L551.752 314.029L546.324 343.957L546.311 344.029L546.278 344.094L543.63 349.299L543.554 349.449L543.402 349.523L537.057 352.584L536.933 357.011L536.924 357.347L536.609 357.466L528.639 360.459L537.425 364.227L537.722 364.354L537.727 364.677L537.855 371.739L546.246 376.966L558.894 377.315L570.194 374.994L575.644 368.965L575.756 368.84L575.92 368.809L586.146 366.843L591.376 357.162L587.653 354.694L587.279 354.446L587.484 354.048L595.042 339.377L590.533 330.974Z" className="fill-primary" stroke="white"/>
            <path d="M152.785 78.1135L162.797 84.0404L165.416 73.4553L165.446 73.3333L165.53 73.2401L167.759 70.7742L158.445 68.32L152.41 72.3358L152.355 72.3722L152.293 72.3932L140.379 76.4149L140.157 76.4899L139.958 76.3671L127.905 68.9563L122.473 72.2295L122.385 72.2825L122.284 72.2966L111.376 73.8049L104.515 83.3423L105.732 86.3083L113.058 85.9696L113.569 85.946L113.581 86.4574L113.976 103.388L116.657 109.219L123.958 100.925L124.093 100.773L124.295 100.757L130.384 100.284L130.716 100.258L130.868 100.554L134.897 108.428L152.836 101.192L144.895 93.2092L144.64 92.9526L144.804 92.6299L152.085 78.3171L152.326 77.842L152.785 78.1135Z" className="fill-primary" stroke="white"/>
            <path d="M415.361 247.246L419.867 246.611L420.181 246.566L420.355 246.831L426.18 255.703L426.398 256.035L426.124 256.322L423.818 258.738L426.377 257.758L426.486 257.716L426.602 257.727L438.119 258.792L438.69 258.844L438.56 259.403L437.343 264.623L442.849 266.263L443.318 266.402L443.189 266.874L441.733 272.197L441.666 272.44L441.431 272.531L432.813 275.864L431.149 283.644L431.11 283.826L430.961 283.939L424.483 288.813L434.799 289.546L435.06 289.565L435.194 289.79L439.768 297.51L448.538 295.239L448.619 295.218L448.702 295.224L465.647 296.526L465.825 296.539L465.954 296.663L471.201 301.694L483.69 299.624L490.22 295.306L490.249 295.286L490.281 295.271L496.603 292.274L500.631 283.45L500.719 283.258L500.917 283.187L509.722 280.016L513.833 277.099L509.896 268.246L509.849 268.14L509.853 268.023L510.118 261.163L510.13 260.836L510.435 260.717L517.981 257.759L518.111 257.708L518.248 257.732L526.869 259.227L530.053 256.17L528.267 248.76L528.142 248.238L528.671 248.15L534.004 247.264L535.01 240.409L535.046 240.165L535.26 240.045L548.881 232.41L542.61 230.067L542.463 230.012L542.374 229.882L539.453 225.64L525.551 233.231L525.355 233.338L525.145 233.264L516.64 230.27L511.551 233.567L511.363 233.689L511.147 233.63L502.83 231.343L494.696 239.88L494.437 240.152L494.103 239.978L484.572 235.01L484.419 234.931L484.348 234.774L482.446 230.583L471.966 226.521L464.282 234.827L468.647 240.287L468.871 240.568L468.677 240.87L462.984 249.741L462.809 250.015L462.488 249.966L447.906 247.735L439.41 250.305L439.222 250.362L439.046 250.276L428.852 245.307L428.741 245.253L428.668 245.153L424.371 239.281L414.91 241.916L415.361 247.246Z" className="fill-primary" stroke="white"/>
            <path d="M924.914 222.059L924.701 221.884L924.736 221.61L925.508 215.519L923.503 212.334L911.564 220.891L914.341 226.826L919.167 228.414L919.314 228.462L919.408 228.586L927.351 238.995L927.467 239.148L927.452 239.338L926.484 251.44L926.758 251.222L935.638 242.821L937.284 236.258L934.877 230.258L924.914 222.059Z" className="fill-secondary" stroke="white"/>
            <path d="M579.131 95.3328L588.425 97.2156L588.599 97.2508L588.712 97.3875L603.381 115.176L609.078 114.96L611.837 110.136L608.552 104.83L608.509 104.76L608.49 104.681L605.715 92.8718L599.235 72.9007L599.169 72.6973L599.271 72.5091L604.486 62.8377L603.585 56.8515L603.557 56.6646L603.659 56.5058L607.63 50.355L607.725 50.2092L607.889 50.1531L621.392 45.5396L621.545 45.4873L621.699 45.5346L626.73 47.0723L626.915 47.1288L627.014 47.2951L632.706 56.8766L632.766 56.9766L632.775 57.0926L633.518 66.5092L638.119 64.9675L646.652 57.9293L646.726 57.8683L646.818 57.839L651.267 56.4116L646.325 50.595L646.203 50.4515L646.206 50.2632L646.331 42.5499L641.084 41.8958L640.687 41.8463L640.648 41.4481L639.887 33.6286L635.056 32.3003L634.423 32.1261L634.759 31.5619L638.897 24.6292L637.803 19.0865L627.509 16.2121L627.36 16.1707L627.261 16.0531L621.718 9.48804H612.6L605.325 15.524L605.24 15.5941L605.134 15.6223L597.589 17.6332L597.469 17.665L597.349 17.6375L591.45 16.2917L586.738 22.8294L587.759 28.4162L587.805 28.6673L587.628 28.8517L579.023 37.8415L579.023 37.8416L579.017 37.8483L578.224 38.6457L583.732 48.4894L583.823 48.6532L583.785 48.8369L582.503 54.9068L588.483 64.3162L588.54 64.4048L588.556 64.5086L589.879 73.1438L589.903 73.2986L589.834 73.4392L579.131 95.3328Z" className="fill-primary" stroke="white"/>
            <path d="M421.424 259.951L421.441 259.779L421.56 259.655L425.125 255.92L419.692 247.646L415.068 248.298L408.796 251.801L408.739 251.833L408.677 251.849L396.762 254.924L396.625 254.96L396.49 254.918L390.188 252.969L389.616 258.486L398.15 265.65L398.41 265.868L398.303 266.19L395.511 274.582L404.659 280.685L407.049 276.093L407.133 275.932L407.301 275.862L420.395 270.417L421.424 259.951Z" className="fill-primary" stroke="white"/>
            <path d="M899.892 445.335L899.833 445.209L899.847 445.071L900.241 441.287L893.684 447.247L893.311 454.249L903.072 462.644L904.586 455.357L899.892 445.335Z" className="fill-secondary" stroke="white"/>
            <path d="M384.473 202.603L384.621 202.607L384.744 202.691L390.994 206.996L399.377 207.918L402.002 207.171L409.516 194.439L409.903 185.903L407.042 177.343L399.125 179.737L395.51 188.159L395.476 188.239L395.416 188.302L389.459 194.69L389.352 194.804L389.2 194.838L379.404 196.967L379.192 197.013L379.013 196.89L376.527 195.173L371.988 202.271L384.473 202.603Z" className="fill-primary" stroke="white"/>
            <path d="M160.431 218.128L160.469 218.287L160.405 218.437L157.825 224.584L162.455 226.342L183.93 217.788L183.806 207.893L175.758 203.399L165.506 205.918L159.168 212.826L160.431 218.128Z" className="fill-primary" stroke="white"/>
            <path d="M465.574 310.012L466.037 309.979L466.105 310.437L467.256 318.212L473.338 320.985L482.195 318.837L487.42 306.767L483.63 300.649L471.119 302.723L470.872 302.764L470.691 302.591L465.393 297.511L448.71 296.229L440.41 298.379L446.854 306.31L457.482 310.595L465.574 310.012Z" className="fill-primary" stroke="white"/>
            <path d="M535.4 293.481L526.322 297.926L531.098 304.71L540.03 311.048L540.29 311.233L540.232 311.547L538.511 320.891L538.471 321.108L538.284 321.225L531.231 325.614L535.701 336.341L535.982 337.016L535.251 337.033L525.487 337.266L513.983 346.159L513.411 349.02L519.966 355.336L520.033 355.401L520.073 355.487L523.274 362.417L527.026 360.007L527.071 359.978L527.121 359.96L535.941 356.647L536.063 352.252L536.072 351.948L536.346 351.815L542.813 348.695L545.351 343.706L550.744 313.971L545.649 297.395L541.146 292.365L535.4 293.481Z" className="fill-primary" stroke="white"/>
            <path d="M787.309 299.429L787.354 299.403L787.402 299.387L796.288 296.468L802.152 290.448L798.867 285.121L789.445 281.192L781.486 291.744L776.233 300.428L774.019 307.055L787.309 299.429Z" className="fill-primary" stroke="white"/>
            <path d="M208.759 182.566L208.757 182.565L201.841 177.361L200.016 186.17L199.957 186.454L199.681 186.544L186.93 190.687L187.041 194.626L197.388 196.167L200.8 189.392L201.058 188.881L201.53 189.205L208.413 193.937L208.693 194.129L208.618 194.46L206.854 202.23L210.992 204.188L211.368 204.367L211.261 204.769L208.481 215.179L208.441 215.331L208.32 215.432L197.111 224.873L200.849 231.985L209.478 230.058L209.865 229.971L210.037 230.329L213.643 237.812L216.005 231.783L216.179 231.337L216.632 231.492L227.193 235.103L240.2 230.616L246.988 216.034L250.174 208.064L246.877 204.572L239.009 200.47L238.924 200.426L238.862 200.354L232.696 193.238L228.61 194.012L228.306 194.07L228.119 193.823L222.441 186.329L213.816 186.214L213.654 186.212L213.524 186.115L208.759 182.566Z" className="fill-primary" stroke="white"/>
            <path d="M842.907 478.021L842.907 478.022L842.894 478.018L825.207 473.218L819.126 476.275L816.543 479.631L821.972 485.364L823.698 485.775L835.678 482.983L843.233 478.101L842.907 478.021Z" className="fill-secondary" stroke="white"/>
            <path d="M368.776 230.65L368.256 230.428L368.54 229.939L373.578 221.282L371.606 216.545L363.039 209.804L358.253 211.154L358.114 211.193L357.975 211.152L345.451 207.421L341.653 209.231L341.639 209.238L341.625 209.244L331.961 213.147L331.95 213.152L331.939 213.156L323.593 216.069L319.312 221.053L319.201 221.183L319.033 221.217L305.306 224.021L297.608 233.348L297.495 233.485L297.32 233.52L292.858 234.406L291.688 238.691L299.236 246.896L316.944 241.893L317.349 241.779L317.531 242.158L320.975 249.341L331.267 249.801L331.361 249.805L331.447 249.843L343.198 255.035L351.024 248.043L357.502 241.663L357.737 241.431L358.043 241.557L366.146 244.89L372.546 240.886L372.615 240.843L372.693 240.824L376.767 239.831L378.05 234.623L368.776 230.65Z" className="fill-primary" stroke="white"/>
            <path d="M222.698 185.331L222.942 185.334L223.09 185.529L228.728 192.971L232.4 192.274L232.775 182.441L227.433 175.395L218.612 173.368L209.821 182.109L213.991 185.214L222.698 185.331Z" className="fill-primary" stroke="white"/>
            <path d="M195.511 149.532L193.433 156.136L196.821 166.035L197.021 166.62L196.407 166.693L185.824 167.962L184.34 171.028L192.483 168.325L192.754 168.235L192.968 168.421L201.826 176.099L209.011 181.506L218.103 172.467L218.298 172.272L218.567 172.334L225.939 174.028L225.006 173.194L224.81 173.019L224.843 172.759L226.114 162.647L220.028 160.76L219.935 160.731L219.86 160.669L208.952 151.735L200.609 154.488L200.307 154.587L200.089 154.357L195.511 149.532Z" className="fill-primary" stroke="white"/>
            <path d="M220.963 108.178L221.012 108.11L221.08 108.063L228.532 102.781L228.042 94.2465L219.219 93.1362L214.067 96.0133L213.981 96.0616L213.882 96.0733L204.651 97.1733L211.153 110.582L217.107 113.513L220.963 108.178Z" className="fill-primary" stroke="white"/>
            <path d="M820.155 93.1244L820.499 92.9433L820.76 93.2321L828.858 102.219L835.834 103.666L842.526 96.4215L842.588 96.3536L842.671 96.3126L849.881 92.7462L854.457 75.459L854.517 75.2317L854.73 75.1331L858.269 73.4972L857.944 62.6649L855.763 67.2944L857.633 70.8594L857.856 71.285L857.44 71.5249L847.379 77.3211L847.159 77.4476L846.927 77.3453L836.134 72.581L830.505 74.8671L830.312 74.9454L830.121 74.8637L815.669 68.6979L810.509 71.9917L808.302 82.4328L808.201 82.91L807.721 82.8211L801.109 81.5947L799.928 84.5688L808.945 93.3164L813.045 96.8688L820.155 93.1244Z" className="fill-primary" stroke="white"/>
            <path d="M622.365 277.748L622.036 277.851L621.823 277.58L616.933 271.372L612.458 271.156L602.769 279.251L610.047 289.521L610.083 289.571L610.105 289.628L614.178 300.075L621.302 308.293L628.568 310.264L628.749 310.313L628.853 310.469L635.23 320.004L649.48 323.301L655.922 310.405L655.992 310.266L656.129 310.19L670.321 302.395L670.819 295.713L665.047 284.824L656.864 283.568L646.782 283.685L634.14 287.45L633.125 287.752L633.537 286.776L636.851 278.917L632.659 274.529L622.365 277.748Z" className="fill-primary" stroke="white"/>
            <path d="M823.689 486.789L823.635 486.787L823.583 486.775L821.652 486.315L806.989 486.2L802.798 499.637L811.923 499.747L812.184 499.75L812.331 499.966L815.376 504.461L815.643 504.856L815.262 505.142L810.6 508.632L811.984 518.53L816.798 521.812L824.475 525.35L823.465 507.965L823.435 507.451L823.949 507.436L827.454 507.332L827.965 493.958L827.983 493.501L828.439 493.478L843.307 492.722L835.446 487.022L823.689 486.789Z" className="fill-secondary" stroke="white"/>
            <path d="M133.242 158.25L133.21 158.089L133.278 157.941L135.411 153.347L134.906 152.595L121.329 151.324L113.394 153.382L103.71 168.265L105.385 172.647L122.248 177.631L130.909 176.245L136.217 173.046L133.242 158.25Z" className="fill-primary" stroke="white"/>
            <path d="M140.155 185.278L140.224 185.116L140.38 185.034L147.711 181.168L136.761 173.885L131.632 176.976L133.145 185.872L137.331 191.795L140.155 185.278Z" className="fill-primary" stroke="white"/>
            <path d="M602.817 277.906L611.966 270.261L612.116 270.136L612.311 270.146L616.736 270.359L617.747 260.539L617.756 260.451L617.795 260.371L620.924 253.964L621.048 247.374L615.987 244.095L615.835 243.996L615.781 243.824L612.869 234.479L612.835 234.371L612.851 234.259L613.846 227.368L594.556 222.394L594.032 222.259L594.208 221.747L597.385 212.52L597.515 212.141L597.914 212.186L606.99 213.214L609.516 208.914L608.772 203.815L602.779 198.004L597.025 196.886L586.201 203.411L586.142 203.447L586.075 203.466L577.409 205.822L578.855 211.856L581.953 215.431L582.269 215.795L581.916 216.124L573.007 224.437L566.727 243.023L568.813 263.988L575.304 268.804L579.501 264.613L579.927 264.188L580.267 264.685L584.82 271.353L590.154 269.579L590.287 269.535L590.424 269.567L603.265 272.524L603.726 272.63L603.646 273.096L602.817 277.906Z" className="fill-primary" stroke="white"/>
            <path d="M84.2947 151.969L84.2295 151.823L84.2608 151.667L85.4596 145.668L65.0747 146.568L64.5964 150.524L64.5445 150.953L64.1124 150.963L49.9881 151.314L47.2863 153.549L57.4816 156.369L57.939 156.496L57.8365 156.959L55.4542 167.724L55.3769 168.073L55.0215 168.113L52.7386 168.368L59.2266 176.589L63.1123 174.962L63.2418 174.907L63.3806 174.928L81.4973 177.684L81.9976 166.727L80.6954 162.072L80.6146 161.784L80.8314 161.576L86.269 156.379L84.2947 151.969Z" className="fill-primary" stroke="white"/>
            <path d="M47.0606 72.8505L50.4767 78.7454L54.2754 72.4871L54.4128 72.2606L54.6775 72.2472L84.757 70.7211L93.9197 58.3829L94.0431 50.1956L88.4434 48.918L79.0979 55.993L79.031 56.0436L78.9513 56.0697L66.6404 60.0914L66.503 60.1363L66.3628 60.1009L55.1104 57.2619L54.9688 57.2262L54.8686 57.1198L50.0888 52.0409L40.6781 50.6023L39.7062 55.921L39.6844 56.04L39.6107 56.1358L32.112 65.8927L28.2465 76.4854L31.4095 82.0287L34.9132 81.6113L46.3203 72.707L46.7728 72.3538L47.0606 72.8505Z" className="fill-primary" stroke="white"/>
            <path d="M759.167 190.167L759.051 189.895L759.233 189.663L763.741 183.909L762.607 173.662L759.867 172.721L755.964 180.034L755.802 180.335L755.463 180.295L736.874 178.056L723.273 180.645L725.767 186.269L729.295 190.322L734.772 190.891L747.859 191.246L747.908 191.247L747.957 191.258L760.9 194.232L759.167 190.167Z" className="fill-primary" stroke="white"/>
            <path d="M799.529 125.075L799.527 125.068L799.524 125.061L795.975 113.782L791.811 108.032L786.327 108.245L782.601 118.691L782.531 118.888L782.342 118.976L777.043 121.456L776.009 133.117L775.951 133.777L775.333 133.54L769.94 131.475L765.351 133.012L771.604 146.085L785.881 147.361L786.241 147.393L786.323 147.745L789.097 159.598L796.27 148.301L796.351 148.173L796.49 148.111L805.887 143.971L806.754 135.106L802.118 132.195L801.97 132.102L801.912 131.936L799.529 125.075Z" className="fill-secondary" stroke="white"/>
            <path d="M239.502 150.2H239.617L239.721 150.25L241.914 151.319V134.418L237.008 127.56L229.653 128.09L231.254 135.572L231.325 135.902L231.044 136.091L222.964 141.534L226.59 144.879L233.192 150.2H239.502Z" className="fill-primary" stroke="white"/>
            <path d="M770.31 549.792L770.198 550.334L769.67 550.168L760.275 547.218L757.235 553.068L761.621 555.928L775.067 555.466L782.486 549.61L778.35 544.436L771.167 545.633L770.31 549.792Z" className="fill-secondary" stroke="white"/>
            <path d="M447.727 246.745L447.835 246.712L447.947 246.729L462.32 248.928L467.644 240.631L463.326 235.23L447.346 229.846L437.857 227.988L426.714 232.337L425.115 238.602L429.404 244.463L439.31 249.291L447.727 246.745Z" className="fill-primary" stroke="white"/>
            <path d="M303.494 149.774L303.359 149.309L303.819 149.159L311.578 146.617L316.963 137.103L306.518 135.15L297.967 143.37L297.807 143.524L297.586 143.509L282.279 142.447L274.679 143.433L275.711 147.532L281.75 156.33L281.807 156.413L281.827 156.511L284.343 168.577L284.385 168.779L284.271 168.953L279.252 176.61L291.752 179.044L297.313 176.964L297.471 176.905L297.633 176.954L304.781 179.121L311.358 173.354L310.024 168.042L302.507 163.063L302.178 162.844L302.314 162.473L305.037 155.058L303.494 149.774Z" className="fill-primary" stroke="white"/>
            <path d="M90.7283 144.587L90.6128 144.763L90.4058 144.804L86.4985 145.568L85.3911 151.109L102.711 147.212L104.065 138.853L101.02 136.55L95.6283 137.098L90.7283 144.587Z" className="fill-primary" stroke="white"/>
            <path d="M962.522 308.031L962.534 308.085L962.535 308.14L962.667 329.223L967.071 340.692L972.6 337.958L974.129 332.379L974.228 332.018L974.602 332.011L980.942 331.898L984.121 326.532L979.251 323.594L978.878 323.37L979.049 322.97L986.33 305.936L986.357 305.873L986.4 305.819L995.363 294.677L993.333 288.667L993.267 288.474L993.356 288.289L997.592 279.536L997.624 279.47L997.674 279.416L1003.4 273.159L995.736 257.175L984.026 256.72L976.489 261.971L975.35 265.363L975.285 265.555L975.107 265.648L965.888 270.453L967.426 281.561L968.614 287.221L968.677 287.523L968.437 287.715L963.522 291.644L960.7 300.124L962.522 308.031Z" className="fill-secondary" stroke="white"/>
            <path d="M187.023 113.074L186.143 126.441L193.411 129.103L203.795 124.177L205.855 120.278L202.828 113.29L200.97 111.941L187.023 113.074Z" className="fill-primary" stroke="white"/>
            <path d="M792.126 521.641L792.177 521.687L792.213 521.745L794.944 526.07L804.929 524.149L810.983 518.626L809.564 508.478L809.523 508.185L809.76 508.008L814.276 504.627L811.645 500.743L802.171 500.629L795.849 502.098L793.436 507.317L793.311 507.588L793.013 507.606L785.371 508.069L781.16 511.728L792.126 521.641Z" className="fill-secondary" stroke="white"/>
            <path d="M571.805 208.637L571.898 208.642L571.983 208.68L577.675 211.223L576.35 205.699L571.526 199.874L571.406 199.729L571.412 199.54L571.67 190.546L567.078 186.443L556.005 185.978L555.669 185.964L555.555 185.647L550.79 172.399L550.758 172.309L550.761 172.214L550.996 164.741L525.31 180.463L525.282 180.48L525.253 180.494L515.977 184.638L527.993 193.474L528.181 193.612L528.196 193.846L528.725 202.363L528.739 202.585L528.584 202.743L522.739 208.711L532.108 210.618L535.913 208.352L536.068 208.259L536.246 208.287L545.249 209.707L545.45 209.739L545.572 209.903L552.845 219.706L554.369 215.814L554.411 215.707L554.495 215.63L562.438 208.296L562.593 208.153L562.804 208.164L571.805 208.637Z" className="fill-primary" stroke="white"/>
            <path d="M572.099 223.999L572.14 223.879L572.232 223.793L580.882 215.724L578.092 212.504L571.659 209.63L562.961 209.173L555.258 216.286L553.517 220.73L556.581 228.714L564.298 235.494L564.403 235.586L564.445 235.719L566.234 241.36L572.099 223.999Z" className="fill-primary" stroke="white"/>
            <path d="M229.491 88.3473L229.521 88.2386L229.594 88.1532L236.965 79.581L231.271 80.806L231.055 80.8523L230.876 80.725L222.162 74.5415L207.195 85.0321L205.177 88.9779L204.454 96.1895L213.666 95.0919L218.875 92.1829L219.018 92.1029L219.181 92.1234L228.15 93.252L229.491 88.3473Z" className="fill-primary" stroke="white"/>
            <path d="M949.836 370.927L949.812 370.955L949.785 370.979L941.174 378.443L943.398 384.625L950.408 390.89L955.982 390.023L961.841 375.342L956.392 370.134L956.193 369.944L956.247 369.675L957.86 361.606L949.836 370.927Z" className="fill-secondary" stroke="white"/>
            <path d="M920.724 26.2326L920.878 26.2306L921.007 26.3159L928.951 31.5826L936.317 27.1574L944.972 19.3093L940.826 12.6847L940.73 12.531L940.754 12.3514L941.681 5.60886L941.707 5.41573L941.858 5.29153L946.101 1.78433L897.893 1.22352L898.705 2.00446L898.897 2.18871L898.851 2.45052L897.924 7.77354L897.859 8.14983L897.479 8.1856L880.279 9.80353L879.409 18.0272L882.038 24.6294L893.895 26.587L920.724 26.2326Z" className="fill-primary" stroke="white"/>
            <path d="M892.396 156.266L892.109 156.184L892.046 155.892L891.294 152.417L883.758 147.141L880.667 148.72L882.697 157.447L882.717 157.535L882.706 157.625L881.813 164.464L887.445 169.612L887.822 169.956L887.47 170.326L879.269 178.933L879.395 197.513L896.319 190.691L896.458 190.635L896.604 190.664L901.236 191.584L913.509 187.313L917.55 180.316L915.658 172.877L908.385 167.424L908.342 167.391L908.307 167.35L900.858 158.709L892.396 156.266Z" className="fill-secondary" stroke="white"/>
            <path d="M974.466 264.854L975.555 261.611L973.12 245.925L963.542 245.362L958.153 246.784L957.644 260.53L965.463 269.546L974.466 264.854Z" className="fill-secondary" stroke="white"/>
            <path d="M826.362 373.293L826.126 373.021L826.31 372.711L829.068 368.055L825.825 369.543L821.789 382.109L821.724 382.31L821.535 382.404L815.997 385.153L817.935 389.808L818.118 390.247L817.687 390.452L809.802 394.188L816.801 398.323L817.029 398.458L817.046 398.723L817.431 405.03L826.036 413.064L838.886 414.687L852.651 411.124L854.285 402.479L852.701 398.839L849.992 400.149L849.675 400.303L849.424 400.056L839.985 390.802L834.947 389.417L834.66 389.338L834.593 389.047L832.635 380.533L826.362 373.293Z" className="fill-secondary" stroke="white"/>
            <path d="M898.431 435.743L898.407 435.688L898.396 435.629L897.119 428.438L890.328 425.404L882.777 425.986L882.596 426L882.448 425.894L875.202 420.736L870.725 435.294L871.19 435.792L871.19 435.792L871.192 435.795L876.498 441.576L885.056 442.735L885.13 442.745L885.198 442.776L893.105 446.423L900.271 439.909L898.431 435.743Z" className="fill-secondary" stroke="white"/>
            <path d="M870.516 211.955L870.728 211.994L870.846 212.174L874.98 218.524L881.938 220.522L885.83 216.821L898.164 204.392L900.677 192.493L896.554 191.674L879.399 198.589V203.575V203.88L879.127 204.02L865.477 211.036L870.516 211.955Z" className="fill-secondary" stroke="white"/>
            <path d="M58.9793 121.939L59.1056 121.915L59.2276 121.955L66.5684 124.372L75.0182 119.796L78.8556 114.597L72.8566 104.788L66.7375 100.004L55.6923 101.397L55.5426 101.416L55.4074 101.349L50.7492 99.0367L40.5436 99.1535L40.487 99.1542L40.4317 99.1422L30.9194 97.0748L25.7848 102.782L24.093 113.254L33.4754 109.661L33.7351 109.561L33.9566 109.73L41.8995 115.763L41.9584 115.807L42.0018 115.867L47.9059 124.009L58.9793 121.939Z" className="fill-primary" stroke="white"/>
            <path d="M911.577 86.5453L911.677 86.3946L911.851 86.3429L917.975 84.519L911.802 74.5918L906.694 73.1057L902.512 81.4856L902.299 81.9127L901.862 81.7196L888.978 76.0199L884.584 87.7985L886.797 96.7569L886.917 97.2422L886.432 97.3622L881.19 98.6576L893.337 108.426L900.042 103.339L900.166 103.246L900.32 103.238L907.733 102.883L907.867 102.877L907.985 102.938L914.79 106.428L918.83 101.892L912.578 102.579L912.312 102.608L912.14 102.403L906.183 95.3056L905.943 95.0189L906.15 94.7073L911.577 86.5453Z" className="fill-primary" stroke="white"/>
            <path d="M51.2174 79.4502L58.3573 81.8294L83.121 81.9452L84.4432 71.7392L54.9909 73.2335L51.2174 79.4502Z" className="fill-primary" stroke="white"/>
            <path d="M193.656 223.778L193.72 223.773L193.783 223.783L196.335 224.218L207.554 214.769L210.186 204.914L206.062 202.963L205.699 202.792L205.788 202.4L207.566 194.569L201.434 190.354L198.117 196.94L197.955 197.263L197.597 197.21L187.162 195.656L189.03 206.448L189.109 206.905L188.659 207.018L184.806 207.986L184.932 218.084L185.915 224.448L193.656 223.778Z" className="fill-primary" stroke="white"/>
            <path d="M447.57 228.871L447.602 228.877L447.633 228.888L463.477 234.226L471.057 226.032L444.284 209.009L435.669 213.99L429.646 225.337L429.619 225.389L429.581 225.432L425.167 230.472L426.373 231.396L437.628 227.003L437.763 226.95L437.906 226.978L447.57 228.871Z" className="fill-primary" stroke="white"/>
            <path d="M482.997 229.724L483.188 229.798L483.272 229.984L485.187 234.203L494.231 238.918L501.882 230.889L489.443 223.403L489.244 223.283L489.207 223.054L488.707 219.926L480.369 212.934L475.943 212.604L469.735 216.995L469.519 217.149L469.271 217.055L465.682 215.705L472.159 225.523L482.997 229.724Z" className="fill-primary" stroke="white"/>
            <path d="M464.334 200.951L464.371 200.824L464.468 200.731L467.916 197.431L464.904 190.972L462.467 191.161L460.481 194.6L460.335 194.853L460.044 194.85L446.276 194.732L446.209 194.731L446.144 194.713L439.321 192.788L440.124 199.459L445.569 202.061L445.913 202.225L445.846 202.6L444.846 208.185L470.125 224.257L463.999 214.972L463.981 214.944L463.967 214.915L461.716 210.302L461.631 210.128L461.686 209.941L464.334 200.951Z" className="fill-primary" stroke="white"/>
            <path d="M802.943 187.939L802.943 187.939L802.944 187.927L803.718 176.399L789.993 167.961L784.207 178.527L783.422 187.531L783.396 187.828L783.121 187.947L779.919 189.324L782.62 202.885L782.638 202.973L782.624 203.062L781.545 209.806L790.896 207.962L798.337 201.078L798.576 200.857L798.875 200.986L802.313 202.473L802.943 187.939Z" className="fill-primary" stroke="white"/>
            <path d="M851.528 506.844L851.534 506.722L851.596 506.617L856.941 497.531L856.732 495.477L844.721 493.651L828.946 494.453L828.434 507.836L828.416 508.303L827.949 508.317L824.492 508.419L825.503 525.819L828.957 527.362L833.698 524.464L844.635 511.982L844.771 511.827L844.976 511.813L851.286 511.371L851.528 506.844Z" className="fill-secondary" stroke="white"/>
            <path d="M922.225 155.331L922.109 155.309L922.015 155.236L907.838 144.312L892.31 152.387L892.96 155.389L901.278 157.79L901.42 157.831L901.518 157.944L909.028 166.657L916.398 172.184L916.539 172.289L916.583 172.46L918.48 179.919L925.9 180.472L932.403 176.382L928.172 171.738L927.953 171.497L928.085 171.198L932.748 160.666L930.606 156.944L922.225 155.331Z" className="fill-secondary" stroke="white"/>
            <path d="M936.283 408.633L936.234 408.504L936.257 408.369L937.952 398.467L932.602 386.856L924.606 387.423L924.396 387.438L924.238 387.297L919.934 383.451L913.744 384.97L911.16 397.318L911.059 397.802L910.574 397.706L903.184 396.251L901.166 401.765L905.926 409.007L906.088 409.252L905.952 409.513L904.099 413.061L904.064 413.128L904.01 413.182L897.937 419.302L898.065 428.042L899.307 435.032L925.09 434.45L925.138 434.449L925.185 434.457L930.391 435.343L933.782 429.058L939.124 416.018L936.283 408.633Z" className="fill-secondary" stroke="white"/>
            <path d="M950.568 403.628L950.517 403.459L950.583 403.296L955.545 391.102L950.328 391.913L950.095 391.949L949.919 391.791L942.811 385.44L933.646 386.733L938.924 398.188L938.989 398.329L938.963 398.482L937.265 408.401L940.128 415.843L940.199 416.029L940.124 416.212L934.899 428.966L942.557 431.519L942.34 428.12L942.331 427.98L942.396 427.856L948.353 416.5L948.402 416.407L948.484 416.342L953.25 412.531L950.568 403.628Z" className="fill-secondary" stroke="white"/>
            <path d="M824.509 289.427L824.594 289.43L824.674 289.462L828.395 290.945L822.815 288.247L822.493 288.092L822.536 287.737L823.828 277.121L815.027 269.598L804.513 269.038L802.072 282.016L810.398 281.031L810.717 280.994L810.884 281.268L815.643 289.082L824.509 289.427Z" className="fill-primary" stroke="white"/>
            <path d="M833.739 160.569L832.165 168.606L836.852 177.797L837.121 178.324L836.557 178.501L832.181 179.875L834.882 186.348L834.989 186.604L834.829 186.83L831.839 191.06L835.267 196.858L841.005 197.998L841.311 198.059L841.391 198.361L844.543 210.329L849.105 216.278L850.432 215.457L860.635 208.561L864.886 199.468L863.861 189.972L859.23 185.603L859.067 185.45L859.074 185.226L859.598 166.359L852.74 157.457L851.529 156.645L843.08 163.624L843.013 163.679L842.931 163.708L837.371 165.719L836.988 165.858L836.775 165.511L833.739 160.569Z" className="fill-primary" stroke="white"/>
            <path d="M465.171 189.948L465.247 189.942L465.32 189.958L477.817 192.78L486.555 191.912L480.442 179.317L471.604 173.676L459.599 183.726L462.478 190.157L465.171 189.948Z" className="fill-primary" stroke="white"/>
            <path d="M383.571 243.002L384.239 239.021L377.3 240.731L377.298 240.731L373.335 241.697L374.105 254.647L374.133 255.114L373.669 255.173L370.159 255.621L362.422 265.029L364.299 268.581L375.125 263.801L375.188 263.773L375.255 263.764L384.108 262.484L388.617 258.455L389.241 252.429L383.638 243.347L383.54 243.187L383.571 243.002Z" className="fill-primary" stroke="white"/>
            <path d="M51.4027 178.881L55.2657 185.461L55.5215 185.897L55.083 186.148L50.8188 188.591L57.2701 191.164L57.558 191.279L57.5832 191.588L58.4613 202.351L61.9899 201.436L62.0569 201.418L62.1261 201.42L67.4676 201.534L74.706 194.044L74.325 188.826L74.2826 188.246L74.8626 188.291L87.5665 189.283L81.6557 178.719L63.3703 175.938L59.2637 177.659L59.2071 177.682L59.1464 177.692L51.4027 178.881Z" className="fill-primary" stroke="white"/>
            <path d="M262.042 136.447L262.058 136.309L262.143 136.198L267.734 128.996L267.862 120.77L263.374 111.948L255.338 109.031L245.219 116.492L245.688 118.902L245.738 119.161L245.553 119.349L237.906 127.093L242.823 133.966L242.917 134.097V134.257V150.87L248.144 145.386L248.216 145.311L248.311 145.271L259.166 140.658L259.55 140.495L259.78 140.844L261.273 143.12L262.042 136.447Z" className="fill-primary" stroke="white"/>
            <path d="M317.378 194.929L317.164 194.702L317.273 194.41L320.279 186.349L319.774 179.018L312.86 174.556L311.994 174.126L305.23 180.056L305.021 180.24L304.755 180.159L297.898 178.08L295.624 193.769L298.938 199.236L303.81 203.818L315.048 205.803L315.218 205.833L315.334 205.963L323.451 215.058L331.249 212.337L330.764 205.071L323.334 201.179L323.26 201.14L323.202 201.08L317.378 194.929Z" className="fill-primary" stroke="white"/>
            <path d="M102.684 168.384L102.593 168.146L102.732 167.933L112.363 153.13L103.079 148.152L85.4561 152.117L87.3247 156.291L87.4688 156.613L87.2138 156.857L81.7381 162.091L82.8587 166.096L89.6926 164.875L89.8791 164.841L90.0408 164.94L103.889 173.382L104.414 172.912L102.684 168.384Z" className="fill-primary" stroke="white"/>
            <path d="M371.88 173.976L369.217 177.05L369.074 177.216L368.855 177.223L361.574 177.459L361.486 177.462L361.401 177.434L353.035 174.674L348.342 177.96L348.213 178.051H348.055H338.742L341.103 183.747L341.202 183.984L341.066 184.203L337.89 189.311L340.709 196.411L340.735 196.477L340.742 196.547L341.864 208.021L345.192 206.435L345.366 206.352L345.55 206.407L358.12 210.151L362.35 208.958L360.214 205.744L359.826 205.16L360.505 204.983L370.774 202.312L375.744 194.539L364.914 183.941L364.561 183.595L364.903 183.238L372.481 175.319L371.88 173.976Z" className="fill-primary" stroke="white"/>
            <path d="M172.204 126.648L185.155 126.203L186.021 113.043L171.097 111.026L168.557 116.537L172.204 126.648Z" className="fill-primary" stroke="white"/>
            <path d="M577.414 39.247L566.998 45.2628L566.936 45.299L566.865 45.316L560.083 46.9475L551.561 53.3907L551.513 53.4274L551.457 53.4514L542.157 57.4354L544.93 73.2058L551.938 73.4292L552.485 73.4467L552.418 73.9907L551.407 82.1181L557.262 84.3444L557.598 84.4722L557.584 84.8314L557.325 91.4164L560.713 96.6858L570.913 99.648L578.012 95.3428L588.867 73.1404L587.583 64.7638L581.549 55.2709L581.44 55.0989L581.482 54.8994L582.767 48.8137L577.414 39.247Z" className="fill-primary" stroke="white"/>
            <path d="M292.355 233.486L292.357 233.486L296.948 232.574L304.229 223.752L297.689 220.779L292.754 229.259L292.625 229.481L292.369 229.506L279.926 230.689L279.678 230.712L279.51 230.529L272.494 222.867L266.814 222.426L261.521 227.156L259.906 232.704L263.794 238.308L267.524 239.975L275.739 239.392L275.889 239.381L276.02 239.455L284.821 244.411L283.75 235.693L283.694 235.234L284.148 235.142L292.355 233.486Z" className="fill-primary" stroke="white"/>
            <path d="M754.861 104.991L754.712 104.899L754.653 104.734L749.736 90.8591L740.28 90.5168L739.306 90.4815L739.903 89.7109L745.644 82.3007L738.95 71.833L738.788 71.5808L738.935 71.3195L742.28 65.3417L740.892 59.8195L733.699 48.801L714.448 51.5719L710.46 55.3655L710.241 55.5734L709.955 55.477L703.058 53.1512L688.418 55.604L683.837 57.6509L686.092 65.9346L693.477 79.4864L693.531 79.5851L693.537 79.6973L693.935 86.6765L693.939 86.7613L693.916 86.843L690.301 99.4313L707.244 96.2681L707.527 96.2152L707.715 96.4337L714.711 104.573L728.325 117.092L728.377 117.14L728.413 117.2L737.985 132.9L741.128 134.023L741.292 134.082L741.384 134.229L748.324 145.345L751.973 147.133L755.862 143.115L757.551 136.151L757.604 135.933L757.801 135.828L764.42 132.279L764.457 132.259L764.497 132.245L767.773 131.148L762.204 130.376L761.896 130.333L761.798 130.037L759.415 122.822L759.341 122.596L759.468 122.396L763.206 116.524L762.08 109.484L754.861 104.991Z" className="fill-primary" stroke="white"/>
            <path d="M938.413 158.139L933.699 160.982L929.129 171.305L933.543 176.15L933.945 176.592L933.439 176.91L926.291 181.405L926.152 181.493L925.988 181.48L918.358 180.912L914.358 187.837L914.992 194.866L918.277 199.042L927.809 204.086L934.985 191.032L935.081 190.858L935.27 190.797L939.912 189.299L946.971 181.706L947.077 181.592L947.229 181.558L956.771 179.426L954.46 171.384L942.068 164.623L941.939 164.553L941.869 164.425L938.413 158.139Z" className="fill-secondary" stroke="white"/>
            <path d="M959.627 205.739L959.456 205.472L959.624 205.204L963.851 198.427L960.803 196.671L960.274 199.412L960.228 199.652L960.011 199.763L948.433 205.691L946.746 212.583L951.849 214.174L961.637 211.492L961.809 211.445L961.973 211.518L963.885 212.372L959.627 205.739Z" className="fill-secondary" stroke="white"/>
            <path d="M5.12641 217.81L7.12358 218.628L14.8457 213.567L15.2796 213.283L15.5477 213.727L19.8729 220.887L25.6682 219.895L31.4137 216.512L31.6188 216.391L31.8419 216.474L38.1963 218.84L38.3705 218.905L38.46 219.067L41.886 225.299L47.1158 221.278L47.1986 221.214L47.3001 221.189L56.4519 218.917V216.012L49.6456 213.949L44.8205 217.806L44.5854 217.994L44.3092 217.874L39.6759 215.863L39.5019 215.788L39.4218 215.616L35.4503 207.099L35.3696 206.926L35.4249 206.743L38.8716 195.338L23.2859 188.488L20.8563 193.047L20.7391 193.266L20.4929 193.305L11.857 194.667L10.067 207.346L11.3737 213.767L11.445 214.118L11.1359 214.299L5.12641 217.81Z" className="fill-primary" stroke="white"/>
            <path d="M946.042 19.1342L946.264 19.4889L945.954 19.7699L937.501 27.4353L957.584 37.2031L967.291 34.6009L962.556 28.092L962.474 27.978L962.462 27.8375L960.873 7.84674L960.867 7.76416L960.887 7.68387L962.7 0.555569L947.67 1.78652L942.646 5.9389L941.771 12.3087L946.042 19.1342Z" className="fill-primary" stroke="white"/>
            <path d="M801.374 243.945L797.779 249.851L797.741 249.914L797.685 249.964L786.168 260.255L786.003 260.402L785.784 260.379L768.615 258.609L763.05 258.927L766.206 270.424L772.637 269.209L772.79 269.18L772.933 269.244L782.995 273.738L783.453 273.943L783.247 274.4L779.737 282.18L781.343 290.273L788.878 280.281L789.112 279.972L789.47 280.121L799.398 284.261L799.547 284.323L799.631 284.46L803.119 290.116L810.621 293.133L814.713 289.477L810.198 282.063L801.515 283.09L800.839 283.17L800.965 282.501L803.6 268.49L802.546 255.427L802.531 255.234L802.649 255.081L804.854 252.236L801.374 243.945Z" className="fill-primary" stroke="white"/>
            <path d="M396.148 215.154L396.115 215L396.175 214.855L398.697 208.849L390.757 207.976L390.632 207.962L390.528 207.89L384.296 203.598L371.145 203.249L361.434 205.775L363.517 208.909L372.323 215.839L372.426 215.92L372.476 216.04L374.518 220.944L382.051 224.367H390.542L397.264 220.363L396.148 215.154Z" className="fill-primary" stroke="white"/>
            <path d="M756.307 553.658L755.928 553.411L756.137 553.009L759.33 546.863L747.912 541.989L747.7 541.898L747.631 541.678L745.726 535.549L730.517 531.288L725.61 532.975L715.241 543.257L718.761 545.984L719.107 546.251L718.888 546.629L713.024 556.76L714.538 564.883L723.962 570.913L728.642 569.557L728.847 569.497L729.032 569.605L736.544 573.973L736.226 570.078L736.209 569.875L736.339 569.718L738.589 566.997L738.654 566.919L738.743 566.873L753.04 559.421L753.059 559.411L753.078 559.403L760.414 556.337L756.307 553.658Z" className="fill-secondary" stroke="white"/>
            <path d="M899.298 478.967L899.203 478.943L899.124 478.886L890.204 472.439L883.876 470.969L871.212 478.364L876.62 484.295L876.847 484.544L876.701 484.847L871.671 495.375L871.56 495.606L871.308 495.652L857.767 498.095L852.517 507.019L852.268 511.699L855.085 515.894L893.728 492.249L893.749 492.236L893.772 492.225L913.833 482.539L899.298 478.967Z" className="fill-secondary" stroke="white"/>
            <path d="M862.45 62.3512L862.446 62.3438L862.442 62.3363L856.75 51.6899L856.669 51.5382L856.698 51.3686L857.79 45.082L852.492 44.9792L844.023 53.2036L858.596 60.1107L858.873 60.2417L858.882 60.5475L859.279 73.7961L859.289 74.1264L858.989 74.265L855.364 75.9408L850.79 93.2203L850.732 93.4398L850.529 93.5405L843.198 97.1667L836.531 104.384L837.659 114.802L848.472 117.189L857.25 115.901L857.673 115.839L857.8 116.247L859.628 122.127L865.209 120.004L867.998 116.041L872.476 104.153L872.523 104.029L872.625 103.945L879.773 98.0301L879.861 97.9574L879.972 97.93L885.709 96.5122L883.631 88.1024L875.616 83.713L875.552 83.6782L875.501 83.6267L868.22 76.2925L868.068 76.1389L868.075 75.9227L868.203 72.2811L862.45 62.3512Z" className="fill-primary" stroke="white"/>
            <path d="M179.113 141.709L189.971 138.099L185.047 127.208L173.748 127.596L175.666 128.612L175.944 128.759L175.931 129.074L175.534 139.01L175.531 139.08L175.509 139.147L173.225 146.046L178.972 141.782L179.037 141.735L179.113 141.709Z" className="fill-primary" stroke="white"/>
            <path d="M414.749 201.854L414.614 201.758L414.563 201.6L413.035 196.821L410.194 195.259L402.762 207.853L402.66 208.025L402.468 208.079L399.787 208.842L397.16 215.099L398.274 220.299L403.335 223.464L424.277 229.973L428.79 224.82L434.57 213.929L429.453 212.334L429.376 212.31L429.311 212.264L414.749 201.854Z" className="fill-primary" stroke="white"/>
            <path d="M853.537 43.9997L858.392 44.0939L858.974 44.1052L858.875 44.6793L857.713 51.3705L863.068 61.3861L868.834 57.6589L868.94 57.5905L869.065 57.5804L879.39 56.7523L879.504 56.7432L879.611 56.7844L888.48 60.2151L888.936 60.3915L888.77 60.8514L886.06 68.3486L889.073 74.9689L901.833 80.6132L905.989 72.2869L906.175 71.9135L906.576 72.0301L912.268 73.6864L912.452 73.7399L912.553 73.9025L918.697 83.7838L924.124 72.7625L924.209 72.5888L924.389 72.518L939.325 66.6505L936.538 56.6904L932.042 43.6927L932 43.5712L932.022 43.4444L932.784 39.0236L928.578 32.5325L920.586 27.2341L893.866 27.587L893.822 27.5876L893.778 27.5804L881.599 25.5695L881.321 25.5235L881.216 25.2612L878.557 18.5833L876.088 18.4832L860.528 25.6104L855.28 30.9693L858.963 38.4588L859.127 38.7921L858.856 39.0453L853.537 43.9997Z" className="fill-primary" stroke="white"/>
            <path d="M770.824 146.775L764.431 133.408L758.47 136.603L756.802 143.483L756.77 143.615L756.675 143.713L752.73 147.79L760.561 158.688L760.864 159.111L760.43 159.397L749.511 166.604L759.817 171.647L763.23 172.82L763.365 172.866L763.455 172.976L773.846 185.67L779.368 188.474L782.452 187.147L783.221 178.336L783.23 178.231L783.28 178.139L789.289 167.167L788.39 160.966L785.432 148.327L771.231 147.057L770.947 147.032L770.824 146.775Z" className="fill-primary" stroke="white"/>
            <path d="M204.483 88.1387L206.361 84.468L206.417 84.3575L206.519 84.2862L221.132 74.0429L211.776 70.1343L211.775 70.1338L205.22 67.3776L201.111 69.1617L202.518 86.9975L204.483 88.1387Z" className="fill-primary" stroke="white"/>
            <path d="M414.267 236.166L414.462 236.301L414.48 236.537L414.829 240.901L424.16 238.302L425.728 232.16L424.207 230.995L403.815 224.658L405.026 229.769L414.267 236.166Z" className="fill-primary" stroke="white"/>
            <path d="M589.838 318.229L591.457 330.582L595.981 339.014L606.822 345.472L620.672 343.021L620.786 343L620.897 343.032L636.517 347.528L636.775 347.602L636.856 347.858L639.768 357.084L639.809 357.214L639.779 357.346L638.323 363.733L638.291 363.873L638.19 363.975L631.37 370.845L635.171 373.006L635.721 373.318L635.289 373.781L624.699 385.137L624.566 385.28L624.371 385.295L619.93 385.635L610.86 395.223L613.561 404.529L613.603 404.677L613.556 404.823L611.835 410.146L611.781 410.314L611.633 410.41L592.438 422.949L592.347 423.008L592.239 423.025L586.812 423.853L586.647 423.878L586.5 423.799L580.712 420.678L585.026 426.736L585.118 426.866V427.026V431.359L597.986 439.256L598.027 439.282L598.063 439.315L606.624 447.19L608.554 444.146L608.722 443.882L609.034 443.918L620.286 445.219L620.352 445.226L620.413 445.251L628.402 448.423L636.247 438.196L636.432 437.955L636.73 438.007L643.881 439.262L648.462 434.487L648.555 434.389L648.684 434.352L656.039 432.236L654.823 427.021L654.744 426.683L655.033 426.491L661.192 422.393L674.777 405.701L674.021 399.512L670.104 392.978L670.019 392.836L670.036 392.671L670.693 386.211L669.241 368.631L669.233 368.531L669.264 368.436L672.492 358.512L666.959 351.269L666.807 351.071L666.875 350.831L668.463 345.153L668.556 344.819L668.901 344.789L686.441 343.269L693.527 335.241L691.773 330.314L691.632 329.918L691.997 329.711L700.119 325.103L701.809 311.984L701.82 311.896L701.861 311.818L705.858 304.098L703.477 298.276L697.922 293.635L692.27 302.615L692.254 302.64L692.235 302.663L681.512 315.911L680.888 316.683L680.639 315.722L678.316 306.725L670.818 303.263L656.746 310.992L650.197 324.1L650.021 324.453L649.637 324.364L635.247 321.035L634.072 324.397L634.002 324.594L633.813 324.684L624.546 329.061L624.421 329.12L624.283 329.106L616.278 328.299L612.598 331.246L612.218 331.551L611.904 331.178L603.148 320.786L589.838 318.229Z" className="fill-primary" stroke="white"/>
            <path d="M907.322 466.894L907.605 467.185L907.379 467.522L900.232 478.166L915.29 481.867L920.57 480.294L935.116 462.074L931.917 454.183L931.845 454.007L931.909 453.828L937.204 438.923L937.232 438.845L937.283 438.78L942.269 432.479L934.474 429.88L931.1 436.134L930.929 436.45L930.576 436.39L925.335 435.498L916.777 448.241L916.721 448.326L916.635 448.381L905.557 455.601L903.932 463.418L907.322 466.894Z" className="fill-secondary" stroke="white"/>
            <path d="M243.118 155.879L242.921 155.78L242.861 155.567L241.994 152.471L239.961 151.48L239.605 157.944L239.578 158.432L239.09 158.416L231.752 158.186L227.406 162.279L232.739 166.938L252.859 160.762L243.118 155.879Z" className="fill-primary" stroke="white"/>
            <path d="M746.996 232.655L747.017 232.658L747.037 232.663L754.431 234.518L762.334 231.539L757.656 213.914L753.762 214.113L750.564 223.142L750.536 223.222L750.483 223.287L743.471 232.082L746.996 232.655Z" className="fill-primary" stroke="white"/>
            <path d="M803.982 449.356L804.107 449.359L804.216 449.419L811.162 453.284L816.426 454.292L827.41 446.076L826.524 436.811L823.015 432.667L800.871 425.993L793.956 426.443L787.636 438.08L786.129 445.713L791.546 449.122L803.982 449.356Z" className="fill-secondary" stroke="white"/>
            <path d="M567.985 131.293L567.726 131.392L567.505 131.226L558.819 124.702L554.813 131.535L555.188 136.226L560.289 144.522L560.426 144.745L560.321 144.985L552.019 163.941L551.761 172.148L556.38 184.991L567.297 185.45L567.475 185.457L567.609 185.576L572.507 189.953L572.68 190.108L572.673 190.34L572.414 199.379L576.986 204.9L585.358 202.623L584.392 197.878L584.343 197.635L584.506 197.448L590.633 190.459L592.596 178.299L592.625 178.124L592.757 178.006L597.63 173.651L598.15 162.386L598.157 162.237L598.245 162.116L601.157 158.094L601.241 157.979L601.373 157.925L610.537 154.171L609.458 147.421L603.802 145.737L603.709 145.709L603.633 145.648L587.218 132.518L587.171 132.48L587.134 132.433L582.067 125.867L567.985 131.293Z" className="fill-primary" stroke="white"/>
            <path d="M848.349 264.114L848.365 263.803L848.652 263.68L854.617 261.131L859.146 255.005L848.196 246.346L838.726 248.49L832.962 259.476L836.23 273.609L836.278 273.818L836.16 273.997L830.957 281.9L831.592 291.423L834.564 293.416L834.799 293.573L834.785 293.855L834.533 299.056L842.643 306.19L851.694 304.572L850.679 302.394L840.936 291.924L840.748 291.721L840.819 291.454L843.864 280.098L843.904 279.95L844.021 279.849L847.698 276.676L848.349 264.114Z" className="fill-primary" stroke="white"/>
            <path d="M331.475 204.315L331.723 204.445L331.742 204.725L332.225 211.961L340.902 208.457L339.754 196.715L336.867 189.447L336.774 189.213L336.908 188.998L340.081 183.894L337.692 178.128L328.17 180.17L328.088 180.187L328.005 180.177L320.796 179.304L321.283 186.389L321.291 186.497L321.253 186.598L318.318 194.468L323.871 200.332L331.475 204.315Z" className="fill-primary" stroke="white"/>
            <path d="M791.468 208.793L791.364 208.889L791.226 208.916L781.609 210.813L788.795 230.192L793.218 236.469L799.298 241.348L806.914 233.664L804.469 229.621L804.388 229.487L804.398 229.33L805.32 215.089L803.384 206.556L798.672 202.13L791.468 208.793Z" className="fill-primary" stroke="white"/>
            <path d="M922.222 139.765L922.199 139.592L922.288 139.442L925.259 134.479L925.132 128.909L922.482 121.583L915.847 120.705L910.851 124.711L910.733 124.806L910.583 124.819L904.241 125.386L901.321 131.171L901.285 131.243L901.228 131.299L893.285 139.225L893.248 139.262L893.204 139.29L883.527 145.579L884.121 146.175L891.779 151.536L907.66 143.277L907.943 143.13L908.196 143.325L922.022 153.979L923.4 148.541L922.222 139.765Z" className="fill-secondary" stroke="white"/>
            <path d="M444.795 202.799L439.463 200.25L428.334 195.921L414.121 196.933L415.465 201.137L429.827 211.403L435.234 213.088L443.844 208.11L444.795 202.799Z" className="fill-primary" stroke="white"/>
            <path d="M463.891 162.047L470.629 163.953L470.703 163.764L472.424 159.388L472.48 159.247L472.604 159.16L479.429 154.417L476.202 146.098L461.144 139.022L453.858 139.487L453.724 139.496L453.604 139.436L448.03 136.669L438.003 150.779L444.415 154.525L444.705 154.694L444.658 155.026L443.929 160.236L455.975 162.615L463.717 162.029L463.806 162.022L463.891 162.047Z" className="fill-primary" stroke="white"/>
            <path d="M680.504 9.8536L672.116 17.2385L672.868 24.5174L681.77 35.0455L681.97 35.2815L681.848 35.5655L678.888 42.4655L682.06 49.8347L688.401 54.5942L703.016 52.1456L703.14 52.1249L703.258 52.165L709.988 54.4344L713.874 50.7387L713.989 50.6289L714.147 50.6061L733.872 47.7672L734.187 47.7217L734.362 47.9887L741.775 59.3443L741.82 59.4145L741.841 59.4958L743.297 65.292L743.346 65.4847L743.249 65.658L739.953 71.5479L746.676 82.0602L746.866 82.3572L746.65 82.6358L741.29 89.554L749.794 89.8619L754.017 81.6282L754.073 81.5202L754.171 81.4497L761.452 76.2449L761.518 76.1982L761.594 76.1743L772.322 72.8299L769.542 68.8964L769.174 68.3752L769.768 68.1423L776.839 65.3712L773.99 58.5084L773.916 58.3306L773.98 58.1492L778.36 45.8321L769.544 38.9678L766.312 39.4123L766.089 39.443L765.918 39.2961L752.081 27.4033L738.258 21.8744L738.127 21.8221L738.043 21.7092L729.945 10.8557L718.234 8.18113L707.509 6.77899L683.784 13.9635L683.436 14.0688L683.228 13.7705L680.504 9.8536Z" className="fill-primary" stroke="white"/>
            <path d="M895.598 268.792L895.777 268.69L895.976 268.745L903.934 270.924L909.959 265.981L907.054 256.954L899.833 249.596L865.598 264.598L865.442 264.667L865.276 264.626L854.981 262.064L849.333 264.478L848.708 276.539L856.054 278.542L866.902 278.081L876.56 268.401L876.828 268.132L877.158 268.318L886.842 273.74L895.598 268.792Z" className="fill-secondary" stroke="white"/>
            <path d="M652.974 72.5263L652.951 72.4443L652.957 72.3593L653.742 60.6747L651.89 57.2628L647.213 58.7632L638.683 65.7992L638.611 65.8582L638.523 65.8876L633.386 67.609L629.361 73.4542L630.385 79.2852L630.409 79.4246L630.357 79.5561L623.853 95.9454L633.308 106.361L633.563 106.642L633.36 106.964L627.006 117.019L626.929 117.14L626.8 117.202L616.72 122.052L616.66 122.531L620.892 125.2L621.164 125.372L621.121 125.69L620.372 131.264L628.943 135.97L625.923 132.081L625.745 131.851L625.857 131.582L627.974 126.496L628.019 126.387L628.108 126.31L637.118 118.493L638.935 110.256L638.996 109.98L639.265 109.89L649.781 106.366L662.485 97.0374L658.625 94.7041L658.438 94.5911L658.394 94.3772L656.544 85.4039L652.974 72.5263Z" className="fill-primary" stroke="white"/>
            <path d="M802.428 531.101L802.611 531.068L802.771 531.162L809.972 535.377L815.919 522.423L811.564 519.453L805.503 524.983L805.398 525.078L805.26 525.105L795.252 527.029L795.81 532.306L802.428 531.101Z" className="fill-secondary" stroke="white"/>
            <path d="M121.163 150.334L121.248 150.312L121.335 150.32L134.926 151.593L138.866 145.211L136.746 139.973L133.453 136.466L122.071 132.623L120.014 136.729L119.851 137.055L119.491 136.999L110.537 135.608L105.066 138.942L103.703 147.352L113.145 152.414L121.163 150.334Z" className="fill-primary" stroke="white"/>
            <path d="M233.017 151.201H232.841L232.703 151.09L225.986 145.676L220.105 142.433L209.837 151.167L220.418 159.833L226.532 161.729L231.218 157.316L231.369 157.173L231.576 157.18L238.634 157.401L238.975 151.201H233.017Z" className="fill-primary" stroke="white"/>
            <path d="M903.475 124.688L903.6 124.44L903.877 124.416L910.345 123.838L915.188 119.954L914.427 107.367L907.65 103.891L900.526 104.232L893.633 109.461L893.322 109.697L893.017 109.452L880.097 99.0619L873.365 104.632L868.968 116.305L871.06 124.225L880.273 122.646L880.589 122.592L880.77 122.857L885.138 129.245L885.372 129.587L885.08 129.88L880.901 134.067L883.082 144.677L892.617 138.481L900.466 130.649L903.475 124.688Z" className="fill-secondary" stroke="white"/>
            <path d="M509.013 98.0169L509.111 97.872L509.277 97.8196L521.064 94.1159L522.845 87.6362L522.877 87.5182L522.96 87.4285L527.859 82.1533L525.705 78.8099L518.702 78.9236L518.657 78.9243L518.612 78.9169L502.419 76.2334L488.827 87.3279L488.326 87.7368L488.056 87.1492L486.005 82.6798L474.035 75.6992L470.346 83.4609L477.28 88.3052L477.651 88.5643L477.43 88.9592L473.887 95.2903L481.885 105.319L481.966 105.42L481.988 105.548L483.117 112.271L496.824 113.718L497.68 107.923L497.727 107.604L498.038 107.515L503.687 105.909L509.013 98.0169Z" className="fill-primary" stroke="white"/>
            <path d="M780.075 512.092L779.99 512.015L779.947 511.908L778.355 507.969L767.245 508.19L765.966 528.413L772.549 533.843L791.456 536.967L794.85 532.742L794.222 526.794L791.409 522.337L780.075 512.092Z" className="fill-secondary" stroke="white"/>
            <path d="M664.856 215.195L664.526 215.206L664.386 214.907L660.103 205.746L648.594 201.404L643.419 204.218L648.047 207.423L648.2 207.529L648.246 207.709L650.599 216.822L662.053 229.963L669.024 231.117L669.164 231.14L669.271 231.233L674.449 235.744L682.465 236.654L692.816 230.411L696.593 224.824L696.759 224.578L697.055 224.607L705.102 225.381L704.989 219.245L696.903 219.689L696.554 219.708L696.416 219.388L694.537 215.021L678.507 212.157L675.911 214.697L675.772 214.833L675.578 214.84L664.856 215.195Z" className="fill-primary" stroke="white"/>
            <path d="M158.411 145.686L158.471 145.516L158.626 145.422L164.008 142.181L161.924 140.911L148.17 141.146L148.058 141.148L147.957 141.102L144.371 139.5L137.888 140.132L139.887 145.071L139.982 145.306L139.849 145.522L135.782 152.109L136.245 152.798L149.419 152.567L156.647 150.754L158.411 145.686Z" className="fill-primary" stroke="white"/>
            <path d="M75.3656 189.333L75.7206 194.194L75.7369 194.417L75.5814 194.577L68.0358 202.385L67.884 202.542L67.6657 202.537L62.554 202.428L61.4203 211.092L61.401 211.24L61.3035 211.353L57.4531 215.826V219.08L62.5054 223.484L67.1115 222.179L63.7795 214.902L63.4124 214.101L64.2888 214.197L73.8239 215.248L82.5561 211.576L87.1053 204.027L87.3748 203.579L87.81 203.868L92.1264 206.733L95.9424 203.42L89.7982 199.868L89.5938 199.75L89.5552 199.517L88.0308 190.321L75.3656 189.333Z" className="fill-primary" stroke="white"/>
            <path d="M539.369 224.547L539.764 224.331L540.02 224.702L543.108 229.186L550.028 231.772L555.532 228.778L552.536 220.97L544.888 210.662L536.361 209.317L530.851 215.615L530.771 215.706L530.659 215.751L522.496 218.993L517.275 229.434L525.267 232.247L539.369 224.547Z" className="fill-primary" stroke="white"/>
            <path d="M657.65 85.8238L659.327 93.9597L663.641 96.5675L664.282 96.955L663.678 97.3984L650.308 107.216L650.245 107.263L650.171 107.287L639.849 110.746L638.057 118.868L638.021 119.03L637.896 119.138L628.851 126.987L626.89 131.696L631.084 137.097L641.601 138.374L641.899 138.41L642.007 138.691L645.051 146.616L645.093 146.725L645.082 146.841L644.331 155.01L653.532 156.475L653.666 156.496L653.77 156.581L659.581 161.331L665.682 152.876L666.304 147.879L659.54 138.814L659.41 138.639L659.449 138.425L660.64 131.919L660.675 131.729L660.829 131.612L665.22 128.265L668.183 120.898L669.238 102.763L669.239 102.734L669.244 102.707L671.082 92.029L670.108 86.5876L657.65 85.8238Z" className="fill-primary" stroke="white"/>
            <path d="M835.622 486.024L835.778 486.027L835.905 486.119L844.942 492.672L856.606 494.446L855.661 487.375L844.753 478.308L844.752 478.309L836.148 483.869L836.074 483.916L835.99 483.936L827.704 485.867L835.622 486.024Z" className="fill-secondary" stroke="white"/>
            <path d="M758.744 491.846L758.554 491.656L758.607 491.392L759.837 485.35L750.969 483.039L736.289 492.014L736.048 499.761L743.227 499.982L743.721 499.997L743.711 500.491L743.584 506.985L755.567 516.413L755.605 516.443L755.637 516.479L765.029 527.38L766.273 507.703L765.498 498.577L758.744 491.846Z" className="fill-secondary" stroke="white"/>
            <path d="M958.056 155.934L942.26 158.644L941.607 158.756L941.679 158.097L942.175 153.546L923.36 140.742L924.402 148.503L924.415 148.599L924.391 148.693L922.933 154.448L931.018 156.004L931.242 156.048L931.356 156.246L933.485 159.944L938.343 157.014L938.789 156.745L939.04 157.201L942.675 163.815L954.552 170.294L958.056 155.934Z" className="fill-secondary" stroke="white"/>
            <path d="M176.367 181.988L182.936 171.63L184.829 167.717L174.492 162.375L167.392 165.657L169.668 177.29L174.719 181.804L176.367 181.988Z" className="fill-primary" stroke="white"/>
            <path d="M186.372 189.815L199.093 185.682L200.96 176.672L192.526 169.362L183.7 172.291L177.13 182.65L177.492 188.586L186.372 189.815Z" className="fill-primary" stroke="white"/>
            <path d="M907.682 232.836L907.579 232.53L907.815 232.31L913.373 227.117L910.618 221.228H908.838L891.491 232.972L891.416 233.022L891.327 233.044L886.023 234.315L882.807 237.189L882.673 237.309L882.493 237.316L867.077 237.904L849.273 243.943L848.88 245.611L860.148 254.522L860.527 254.822L860.239 255.211L855.786 261.234L865.353 263.614L899.684 248.57L910.087 239.962L907.682 232.836Z" className="fill-secondary" stroke="white"/>
            <path d="M795.044 501.456L795.147 501.234L795.385 501.179L801.725 499.706L805.949 486.161L763.961 483.838L760.9 485.319L760.893 485.322L760.886 485.325L760.856 485.339L759.637 491.329L766.33 498L766.46 498.129L766.476 498.312L767.23 507.189L778.676 506.962L779.02 506.955L779.149 507.275L780.608 510.884L784.844 507.203L784.972 507.092L785.142 507.081L792.654 506.626L795.044 501.456Z" className="fill-secondary" stroke="white"/>
            <path d="M203.443 96.9775L203.381 96.8503L203.395 96.7095L204.158 89.1044L201.878 87.7803L198.009 87.3194L197.894 87.3057L197.797 87.2429L184.794 78.845L181.322 82.9103L179.068 93.6546L186.162 95.1846L193.741 93.7833L194.265 93.6865L194.329 94.2152L195.225 101.651L200.298 104.485L200.509 104.603L200.548 104.841L201.574 111.144L203.332 112.42L210.062 110.627L203.443 96.9775Z" className="fill-primary" stroke="white"/>
            <path d="M816.605 405.626L816.459 405.49L816.447 405.291L816.066 399.05L808.555 394.613L802.697 393.388L792.912 404.317L790.853 412.828L794.03 425.435L800.896 424.989L800.986 424.983L801.073 425.009L823.445 431.752L823.587 431.794L823.682 431.907L827.388 436.284L827.49 436.404L827.504 436.56L828.365 445.566L836.202 442.065L836.423 441.966L836.638 442.078L848.697 448.383L853.729 441.377L848.175 439.688L847.714 439.548L847.837 439.082L852.132 422.797L846.617 417.196L838.842 415.691L825.753 414.037L825.592 414.016L825.474 413.906L816.605 405.626Z" className="fill-secondary" stroke="white"/>
            <path d="M725.572 277.151L725.313 277.061L725.249 276.794L722.782 266.35L714.147 261.017L707.213 263.082L706.903 263.174L706.692 262.929L700.073 255.24L699.928 255.072L699.956 254.851L700.966 246.831L692.591 243.36L690.162 247.592L690.12 247.665L690.057 247.72L680.293 256.212L680.168 260.37L680.163 260.539L680.056 260.67L673.675 268.538L682.413 280.939L689.364 286.096L689.652 286.309L689.543 286.65L688.637 289.483L697.954 292.408L698.048 292.437L698.125 292.501L704.214 297.588L704.309 297.667L704.356 297.782L706.808 303.778L711.696 306.69L724.252 305.989L724.452 305.978L724.604 306.107L730.143 310.827L735.731 310.486L755.138 306.556L754.896 300.405L749.369 293.629L737.536 286.932L737.421 286.867L737.353 286.753L733.21 279.812L725.572 277.151Z" className="fill-primary" stroke="white"/>
            <path d="M404.297 230.483L404.139 230.374L404.095 230.187L402.686 224.236L397.824 221.194L391.076 225.214L385.34 238.495L384.587 242.981L390.082 251.888L396.651 253.92L408.366 250.896L414.382 247.536L413.879 241.588L413.879 241.586L413.501 236.854L404.297 230.483Z" className="fill-primary" stroke="white"/>
            <path d="M282.196 141.449L282.246 141.443L282.295 141.446L297.431 142.496L305.851 134.401L305.731 127.116L293.825 128.248L293.569 128.272L293.4 128.078L288.58 122.524L273.093 127.024L275.987 136.356L276.027 136.483L275.998 136.613L274.697 142.422L282.196 141.449Z" className="fill-primary" stroke="white"/>
            <path d="M251.979 230.071V229.724L252.305 229.603L260.821 226.442L265.641 222.134L247.72 216.833L241.114 231.022L242.491 234.099L251.979 235.077V230.071Z" className="fill-primary" stroke="white"/>
            <path d="M718.021 358.047L718.111 357.891L718.28 357.828L730.272 353.383L729.786 351.905L722.706 342.243L713.834 342.473L713.592 342.479L713.437 342.293L705.952 333.299L694.363 335.803L687.058 344.079L686.925 344.229L686.726 344.246L669.332 345.754L667.904 350.858L673.445 358.112L673.605 358.321L673.523 358.57L670.245 368.647L671.693 386.173L671.696 386.218L671.692 386.264L671.047 392.605L674.778 398.828L683.087 398.385L686.92 392.219L686.982 392.119L687.082 392.058L696.054 386.517L703.967 379.91L705.785 370.856L705.845 370.557L706.139 370.473L711.768 368.873L718.021 358.047Z" className="fill-primary" stroke="white"/>
            <path d="M961.699 329.496L961.667 329.411L961.666 329.32L961.536 308.508L955.658 306.608L955.478 306.55L955.382 306.388L952.205 301.051L937.843 299.935L939.073 310.928L943.243 313.638L943.377 313.725L943.436 313.874L948.069 325.584L948.222 325.971L947.869 326.192L937.447 332.711L936.473 336.41L940.325 350.177L960.76 354.106L964.396 352.115L962.864 342.645L962.796 342.229L963.195 342.092L966.147 341.078L961.699 329.496Z" className="fill-secondary" stroke="white"/>
            <path d="M122.046 71.3201L126.653 68.5442L126.056 68.3799L125.978 68.3585L125.91 68.3136L101.363 51.9212L95.0398 50.4289L94.9172 58.5603L94.9147 58.7214L94.8186 58.8508L85.497 71.4031L84.1083 82.1232L89.8323 84.3471L90.5926 84.6426L89.9844 85.186L82.7502 91.6503L83.1072 98.4548L87.8977 97.9453L90.8133 93.1871L90.8918 93.0591L91.0278 92.9955L104.761 86.573L103.486 83.4653L103.381 83.2087L103.543 82.9835L110.691 73.047L110.816 72.8731L111.028 72.8438L122.046 71.3201Z" className="fill-primary" stroke="white"/>
            <path d="M681.358 314.509L691.438 302.056L697.034 293.167L687.856 290.286L687.376 290.135L687.53 289.656L688.48 286.685L681.751 281.693L681.687 281.645L681.641 281.58L672.88 269.146L661.609 274.411L661.361 274.527L661.13 274.38L657.385 272.005L652.288 277.724L657.135 282.597L665.445 283.873L665.693 283.911L665.811 284.133L671.768 295.371L671.835 295.498L671.825 295.642L671.321 302.393L678.949 305.915L679.165 306.015L679.224 306.244L681.358 314.509Z" className="fill-primary" stroke="white"/>
            <path d="M99.0868 224.555L114.472 217.903L114.677 217.814L114.88 217.907L122.6 221.468L125.72 213.217L126.004 212.466L126.553 213.052L131.775 218.628L143.645 215.39L142.648 206.931L136.082 195.665L135.965 195.465L136.043 195.246L136.882 192.888L132.267 186.357L132.201 186.264L132.182 186.152L130.676 177.296L122.603 178.588L115.282 202.539L115.234 202.694L115.104 202.792L103.447 211.57L99.0868 224.555Z" className="fill-primary" stroke="white"/>
            <path d="M681.354 50.5541L681.248 50.4742L681.195 50.3519L678.048 43.0415L673.893 43.9447L672.485 53.7736L672.444 54.0635L672.171 54.1691L654.729 60.9149L653.962 72.3411L657.418 84.8091L670.565 85.615L670.957 85.6391L671.026 86.0259L672.085 91.9407L672.101 92.0271L672.086 92.1136L670.238 102.85L669.18 121.039L669.175 121.12L669.145 121.196L666.1 128.767L666.049 128.895L665.939 128.978L661.592 132.291L660.475 138.392L667.229 147.444L667.349 147.605L667.324 147.805L666.771 152.253L676.422 147.995L676.586 147.923L676.759 147.972L687.562 151.005L687.549 150.997L687.514 150.78L684.877 134.285L681.066 125.829L680.981 125.64L681.054 125.446L684.159 117.237L681.725 111.627L681.672 111.504L681.687 111.371L683.011 99.8968L683.063 99.4453L683.517 99.4542L689.223 99.5649L692.932 86.6494L692.546 79.8671L685.187 66.3625L685.159 66.3111L685.143 66.2546L682.76 57.5012L682.648 57.0881L683.039 56.9134L687.294 55.0123L681.354 50.5541Z" className="fill-primary" stroke="white"/>
            <path d="M31.5739 135.564L31.7027 135.432L31.8864 135.415L39.5788 134.716L47.5614 129.734L47.1971 124.736L41.2343 116.512L33.5718 110.692L24.0062 114.355L25.2792 124.138L25.2893 124.216L25.275 124.294L22.442 139.598L26.3651 140.9L31.5739 135.564Z" className="fill-primary" stroke="white"/>
            <path d="M753.662 606.815L753.782 606.846L753.874 606.93L758.771 611.415L770.501 596.475L759.123 590.9L754.339 597.536L754.157 597.789L753.851 597.737L747.277 596.629L745.023 604.577L753.662 606.815Z" className="fill-secondary" stroke="white"/>
            <path d="M367.583 121.297L371.57 111.366L362.759 113.92L362.508 113.992L362.305 113.829L358.315 110.608L338.603 108.377L338.43 108.357L338.307 108.236L335.197 105.158L328.507 128.057L331.837 130.922L332.101 131.15L331.979 131.476L329.801 137.316L333.555 145.646L333.862 146.328L333.114 146.351L321.446 146.702L314.934 150.059V156.974L319.321 160.677L332.174 157.197L332.292 157.165L332.411 157.191L337.525 158.306L343.464 150.402L343.72 146.293L343.754 145.742L344.299 145.831L354.389 147.47L366.201 144.421L366.231 144.413L366.261 144.409L379.583 142.658L382.79 139.156L379.915 134.016L369.048 132.744L368.648 132.697L368.609 132.297L367.55 121.532L367.538 121.411L367.583 121.297Z" className="fill-primary" stroke="white"/>
            <path d="M940.117 378.458L940.001 378.135L940.26 377.911L949.102 370.246L958.34 359.515L958.344 359.509L962.869 354.091L961.082 355.069L960.924 355.156L960.747 355.122L939.832 351.1L939.528 351.042L939.445 350.744L935.473 336.549L935.437 336.418L935.471 336.287L935.696 335.433L932.539 340.465L932.355 340.758L932.016 340.69L924.081 339.088L918.732 344.757L919.773 357.31L919.776 357.347L919.773 357.384L918.979 369.568L918.973 369.664L918.932 369.751L914.696 378.623L914.664 378.688L914.616 378.742L909.913 383.969L913.263 384.057L919.95 382.416L920.206 382.354L920.402 382.529L924.745 386.41L932.857 385.835L942.292 384.504L940.117 378.458Z" className="fill-secondary" stroke="white"/>
            <path d="M855.984 460.687L856.024 460.629L856.079 460.584L868.124 450.852L863.973 445.977H856.262H855.921L855.796 445.66L854.411 442.138L849.271 449.295L846.769 453.765L846.706 453.879L846.595 453.947L838.702 458.803L837.6 463.944L843.965 473.229L844.074 473.387L844.049 473.577L843.578 477.154L844.343 477.341L844.368 477.318L855.984 460.687Z" className="fill-secondary" stroke="white"/>
            <path d="M943.182 153.541L942.745 157.547L958.231 154.89L958.349 148.895L951.411 145.849L943.182 153.541Z" className="fill-secondary" stroke="white"/>
            <path d="M369.51 254.831L369.636 254.678L369.833 254.653L373.076 254.239L372.359 242.18L366.455 245.873L366.237 246.009L366 245.911L357.965 242.607L351.96 248.521L356.156 258.065L361.8 264.205L369.51 254.831Z" className="fill-primary" stroke="white"/>
            <path d="M901.332 439.834L901.385 439.955L901.372 440.088L900.857 445.036L905.319 454.562L916.005 447.598L924.148 435.471L899.649 436.024L901.332 439.834Z" className="fill-secondary" stroke="white"/>
            <path d="M755.777 299.902L755.882 300.032L755.889 300.199L756.133 306.408L767.915 305.176L775.402 299.871L780.543 291.372L778.727 282.218L778.696 282.061L778.762 281.915L782.13 274.447L772.67 270.22L765.952 271.49L752.726 274.681L752.537 274.726L752.367 274.632L749.95 273.295L746.074 277.981L751.158 281.226L751.418 281.392L751.386 281.698L750.215 293.084L755.777 299.902Z" className="fill-primary" stroke="white"/>
            <path d="M933.979 140.061L933.575 140.179L933.39 139.802L930.645 134.227L926.076 135.064L923.39 139.552L942.659 152.664L950.726 145.123L947.303 136.168L933.979 140.061Z" className="fill-secondary" stroke="white"/>
            <path d="M171.617 159.311L171.525 159.208L171.499 159.072L169.148 146.582L164.916 142.801L159.298 146.185L157.505 151.337L157.416 151.592L157.154 151.657L149.887 153.48L148.332 158.822L156.015 165.912L166.696 164.878L173.686 161.647L171.617 159.311Z" className="fill-primary" stroke="white"/>
            <path d="M484.339 199.058L484.398 199.065L484.454 199.087L493.224 202.445L487.065 192.866L477.839 193.782L477.758 193.79L477.679 193.772L466.097 191.157L468.859 197.082L484.339 199.058Z" className="fill-primary" stroke="white"/>
            <path d="M385.354 275.921L385.637 275.713L385.928 275.908L391.812 279.851L394.454 274.594L397.247 266.197L389.102 259.36L384.659 263.33L384.547 263.43L384.398 263.452L375.464 264.743L364.734 269.481L366.646 273.753L366.647 273.754L370.331 281.926L378.837 280.697L385.354 275.921Z" className="fill-primary" stroke="white"/>
            <path d="M176.952 189.521L176.547 189.465L176.522 189.056L176.15 182.97L175.043 182.847L176.311 202.562L184.372 207.064L187.966 206.161L186.059 195.144L186.053 195.109L186.052 195.073L185.932 190.765L176.952 189.521Z" className="fill-primary" stroke="white"/>
            <path d="M864.499 131.158L864.991 121.157L859.483 123.251L858.986 123.44L858.828 122.932L856.969 116.953L849.06 118.113L850.394 123.854L850.436 124.035L850.349 124.201L846.971 130.586L847.477 141.082L850.298 141.818L850.517 141.875L850.619 142.077L854.061 148.938L854.151 149.118L854.092 149.31L852.083 155.815L853.225 156.581L865.509 156.015L868.581 140.234L864.543 131.39L864.493 131.279L864.499 131.158Z" className="fill-secondary" stroke="white"/>
            <path d="M679.3 255.963L679.306 255.745L679.471 255.601L689.336 247.021L691.692 242.917L682.422 237.656L674.633 236.772L672.478 246.739L672.446 246.885L672.34 246.99L668.501 250.775L668.351 250.924L668.14 250.919L640.716 250.33L633.671 253.478L633.594 253.512L633.511 253.519L621.746 254.559L618.731 260.732L617.702 270.733L622.394 276.691L632.655 273.482L632.952 273.389L633.166 273.613L637.8 278.463L638.026 278.701L637.899 279.003L634.868 286.189L646.562 282.706L646.629 282.686L646.699 282.685L655.709 282.581L651.248 278.097L650.916 277.763L651.229 277.412L656.922 271.024L657.205 270.707L657.563 270.934L661.435 273.39L672.733 268.113L679.173 260.171L679.3 255.963Z" className="fill-primary" stroke="white"/>
            <path d="M625.072 228.158L625.186 228.176L625.281 228.242L634.164 234.429L655.91 235.476L661.109 230.399L649.77 217.389L649.692 217.299L649.662 217.185L647.327 208.138L642.45 204.761L635.446 208.972L635.272 209.076L635.075 209.03L628.012 207.394L610.341 209.484L607.687 214.001L607.522 214.281L607.2 214.245L598.199 213.225L595.329 221.561L614.506 226.506L625.072 228.158Z" className="fill-primary" stroke="white"/>
            <path d="M389.325 149.186L389.208 149.179L389.106 149.119L379.722 143.646L366.419 145.394L354.535 148.462L354.433 148.488L354.329 148.472L344.681 146.904L344.451 150.611L344.441 150.761L344.351 150.881L338.13 159.161L337.937 159.418L337.623 159.349L332.315 158.192L319.498 161.663L314.639 168.406L313.658 173.88L320.43 178.251L328.043 179.173L337.89 177.061L337.942 177.05H337.995H347.898L352.667 173.71L352.872 173.567L353.11 173.645L361.631 176.456L368.605 176.23L371.554 172.826L373.645 162.898L373.694 162.662L373.91 162.554L384.5 157.231L384.658 157.151L384.831 157.189L390.379 158.401L394.058 149.496L389.325 149.186Z" className="fill-primary" stroke="white"/>
            <path d="M966.567 221.026L966.602 220.723L966.886 220.615L972.932 218.316L977.222 210.235L971.531 216.222L971.298 216.467L970.984 216.343L965.026 213.977L965.017 213.973L965.007 213.969L961.728 212.504L951.972 215.177L951.831 215.216L951.691 215.173L946.637 213.597L946.519 220.52L952.053 225.915L961.98 226.376L966.077 225.299L966.567 221.026Z" className="fill-secondary" stroke="white"/>
            <path d="M544.491 74.1944L544.085 74.1814L544.015 73.7812L541.105 57.2343L539.464 50.2434L533.759 49.3756L528.752 52.4728L528.367 52.7111L528.092 52.3512L524.562 47.7331L513.044 49.3339L504.928 54.4807L504.861 54.5232L504.784 54.5429L486.394 59.2381L477.54 68.7787L474.476 74.8015L486.645 81.8979L486.781 81.9775L486.847 82.1212L488.695 86.1467L501.962 75.3181L502.137 75.1753L502.359 75.2122L518.731 77.9252L525.966 77.8078L526.243 77.8033L526.394 78.0369L528.91 81.9404L529.12 82.2669L528.856 82.5515L523.776 88.0216L522.04 94.3361L533.991 104.326L549.601 106.186L554.906 104.042L559.79 97.1031L556.398 91.8267L556.313 91.6942L556.319 91.5367L556.57 85.1534L550.684 82.9153L550.317 82.7758L550.365 82.3863L551.357 74.4133L544.491 74.1944Z" className="fill-primary" stroke="white"/>
            <path d="M718.352 246.272L718.358 245.932L718.676 245.812L727.094 242.639L737.89 235.374L734.824 230.632L722.046 227.603L721.853 227.557L721.744 227.391L718.38 222.27L705.77 226.409L705.671 226.441L705.566 226.431L697.257 225.632L693.585 231.064L693.523 231.155L693.429 231.212L683.57 237.158L692.596 242.281L701.701 246.054L702.054 246.2L702.006 246.579L700.975 254.757L707.238 262.032L713.925 260.04L718.222 254.619L718.352 246.272Z" className="fill-primary" stroke="white"/>
            <path d="M680.173 589.548L680.296 601.889L686.083 607.864L698.844 616.964L699.319 616.588L684.859 601.015L684.339 600.454L685.061 600.202L704.359 593.47L713.862 588.752L713.947 588.71L714.041 588.702L722.069 588.008L725.78 584.349L725.854 584.276L725.95 584.239L737.725 579.665L736.681 575.211L728.713 570.577L724.02 571.937L723.803 572L723.612 571.878L714.235 565.877L703.385 582.728L703.315 582.838L703.2 582.899L686.785 591.652L686.593 591.755L686.387 591.684L680.173 589.548Z" className="fill-secondary" stroke="white"/>
            <path d="M378.834 233.872L379.224 234.039L379.122 234.451L377.863 239.563L384.498 237.927L389.922 225.368H381.946H381.838L381.739 225.324L374.34 221.962L369.689 229.954L378.834 233.872Z" className="fill-primary" stroke="white"/>
            <path d="M763.748 174.918L764.754 184.004L764.776 184.206L764.65 184.367L760.199 190.049L762.202 194.745L762.587 195.649L761.63 195.429L747.778 192.246L735.228 191.906L735.106 199.399L742.719 210.122L753.458 213.127L757.91 212.9L771.37 206.477L776.204 201.341L776.402 201.131L776.682 201.197L781.486 202.321L778.905 189.362L773.297 186.515L773.203 186.468L773.136 186.386L763.748 174.918Z" className="fill-primary" stroke="white"/>
            <path d="M839.008 231.691L839.085 231.63L839.18 231.603L848.466 228.914L853.679 224.146L850.471 216.609L849.321 217.32L844.345 223.639L844.24 223.773L844.075 223.814L832.915 226.63L826.771 231.069L826.557 231.224L826.308 231.134L820.923 229.186L807.826 234.168L800.231 241.831L801.64 242.382L801.837 242.459L801.919 242.654L805.747 251.774L813.989 250.894L816.246 246.862L816.366 246.647L816.609 246.611L830.671 244.536L831.676 237.688L831.705 237.493L831.86 237.369L839.008 231.691Z" className="fill-primary" stroke="white"/>
            <path d="M50.3059 80.2003L50.1263 80.1405L50.0314 79.9767L46.4804 73.849L35.4158 82.486L35.3058 82.5718L35.1673 82.5883L31.6587 83.0063L31.7476 84.8382L40.4613 85.8537L40.7115 85.8828L40.8369 86.1012L43.22 90.2484L50.5106 90.8004L57.4184 82.5704L50.3059 80.2003Z" className="fill-primary" stroke="white"/>
            <path d="M193.264 130.113L186.308 127.566L191.112 138.19L191.112 138.191L195.71 148.287L200.597 153.438L208.808 150.729L219.72 141.447L219.983 141.223L220.286 141.39L222.895 142.828L221.824 141.84L221.359 141.411L221.883 141.058L230.208 135.451L228.577 127.83L225.433 123.076L216.3 127.724L216.138 127.806L215.962 127.766L204.217 125.083L193.65 130.096L193.461 130.185L193.264 130.113Z" className="fill-primary" stroke="white"/>
            <path d="M887.691 315.079L887.731 314.96L887.823 314.875L896.044 307.184L894.542 303.465L887.544 301.344L877.658 306.109L877.166 306.346L876.973 305.835L872.736 294.588L856.267 295.615L851.673 302.16L852.824 304.628L862.789 312.361L863.031 312.548L862.974 312.849L861.091 322.721L872.724 323.85L872.79 323.857L872.852 323.88L883.356 327.838L887.691 315.079Z" className="fill-secondary" stroke="white"/>
            <path d="M491.146 162.683L491.264 162.825L491.261 163.01L491.131 171.876L494.46 178.398L501.914 183.681L514.887 184.028L524.814 179.593L549.098 164.729L528.172 168.109L527.764 168.175L527.622 167.786L526.148 163.724L516.023 163.265L515.709 163.251L515.586 162.961L513.851 158.866L489.88 157.139L488.698 159.731L491.146 162.683Z" className="fill-primary" stroke="white"/>
            <path d="M690.484 152.855L690.436 152.852L690.389 152.838L676.661 148.983L666.492 153.47L660.128 162.288L653.701 181.242L663.19 184.031L681.78 183.795L681.998 183.792L682.148 183.95L688.161 190.257L692.862 192.084L689.027 185.128L688.875 184.853L689.058 184.596L695.809 175.133L695.882 175.032L695.993 174.976L701.544 172.207L705.97 164.065L706.08 163.863L706.306 163.815L711.578 162.693L714.802 152.164L710.209 150.164L698.067 153.431L697.983 153.454L697.897 153.447L690.484 152.855Z" className="fill-primary" stroke="white"/>
            <path d="M734.198 199.843L734.103 199.709L734.106 199.545L734.231 191.841L729.416 191.341L725.424 205.955L725.416 205.982L725.406 206.009L719.149 221.617L722.471 226.674L735.25 229.704L735.446 229.75L735.555 229.919L738.701 234.785L742.195 232.079L749.651 222.729L752.754 213.967L742.281 211.036L742.111 210.988L742.008 210.844L734.198 199.843Z" className="fill-primary" stroke="white"/>
            <path d="M837.092 115.702L836.741 115.625L836.702 115.268L835.55 104.629L828.494 103.165L828.334 103.132L828.224 103.01L820.276 94.1904L813.207 97.9128L812.905 98.0719L812.647 97.8482L808.521 94.273L794.251 100.764L794.158 100.807L794.056 100.809L789.477 100.92L786.743 107.231L792.039 107.025L792.307 107.015L792.464 107.232L796.832 113.264L796.879 113.33L796.904 113.408L800.094 123.543L800.608 122.453L800.786 122.073L801.191 122.183L816.415 126.324L816.535 126.356L816.626 126.442L827.348 136.496L827.45 136.592L827.488 136.726L828.468 140.228L848.074 144.923L849.41 142.62L846.87 141.958L846.514 141.865L846.497 141.498L845.967 130.497L845.96 130.361L846.024 130.24L849.379 123.898L848.036 118.118L837.092 115.702Z" className="fill-secondary" stroke="white"/>
            <path d="M780.47 210.611L780.424 210.488L780.445 210.358L781.563 203.365L776.737 202.236L772.036 207.231L771.972 207.299L771.887 207.339L758.627 213.667L763.418 231.719L763.423 231.737L763.426 231.754L764.929 239.701L775.616 238.577L783.202 234.137L783.375 234.035L783.57 234.082L791.682 236.022L787.943 230.716L787.906 230.663L787.883 230.602L780.47 210.611Z" className="fill-primary" stroke="white"/>
            <path d="M877.213 391.288L877.073 391.138L877.078 390.933L877.336 381.136L874.369 378.892L861.288 386.762L860.954 386.963L860.677 386.687L848.385 374.417L838.141 374.649L837.833 374.656L837.688 374.384L832.832 365.261L830.712 367.25L827.358 372.911L833.476 379.973L833.558 380.067L833.586 380.188L835.505 388.533L840.379 389.873L840.504 389.907L840.597 389.998L849.877 399.097L852.738 397.712L852.868 397.649L853.011 397.665L865.719 399.085L865.815 399.095L865.899 399.141L875.13 404.067L882.944 397.411L877.213 391.288Z" className="fill-secondary" stroke="white"/>
            <path d="M800.331 80.8422L800.369 80.7462L800.442 80.6732L805.753 75.362L803.785 71.1852L803.743 71.095L803.738 70.9954L802.682 48.3585L800.033 40.9473L797.922 44.7193L797.821 44.8996L797.622 44.9561L788.886 47.4402L788.786 47.4685L788.683 47.4549L779.288 46.2069L774.987 58.3013L777.958 65.4573L778.154 65.9285L777.679 66.1146L770.728 68.8389L773.536 72.8126L773.933 73.3739L773.277 73.5785L761.969 77.1035L754.852 82.1911L750.637 90.4078L755.537 104.234L762.801 108.755L762.995 108.875L763.031 109.101L764.223 116.553L764.253 116.74L764.151 116.9L760.439 122.733L762.65 129.428L770.02 130.45L770.076 130.458L770.13 130.478L775.071 132.37L776.072 121.083L776.097 120.796L776.358 120.674L781.729 118.16L785.498 107.592L785.503 107.577L785.51 107.561L788.687 100.227L788.814 99.934L789.133 99.9263L793.929 99.8106L807.731 93.5321L798.991 85.0524L798.751 84.8196L798.874 84.5089L800.331 80.8422Z" className="fill-primary" stroke="white"/>
            <path d="M760.237 354.64L760.35 354.421L760.592 354.377L776.684 351.436L771.032 348.327L770.958 348.287L770.901 348.224L763.382 339.884L754.486 340.345L754.302 340.355L754.156 340.242L747.094 334.818L741.671 345.862L741.601 346.004L741.463 346.08L730.839 351.913L731.362 353.5L735.506 362.296L744.208 366.242L744.291 366.279L744.355 366.344L753.059 375.036L758.838 370.948L757.933 359.284L757.922 359.143L757.987 359.017L760.237 354.64Z" className="fill-primary" stroke="white"/>
            <path d="M101.479 185.132L101.45 185.024L101.47 184.914L103.395 174.251L89.6844 165.892L82.9832 167.09L82.4786 178.141L88.8954 189.609L88.938 189.685L88.9523 189.771L90.5022 199.12L96.7168 202.713L104.184 195.263L101.479 185.132Z" className="fill-primary" stroke="white"/>
            <path d="M831.195 168.907L831.116 168.753L831.15 168.584L832.966 159.312L828.872 152.642L815.879 163.683L811.412 171.334L827.454 180.436L831.345 179.09L831.352 179.088L831.359 179.086L835.692 177.725L831.195 168.907Z" className="fill-primary" stroke="white"/>
            <path d="M110.833 122.831L110.908 122.735L111.018 122.684L115.122 120.795L116.013 110.216L113.434 104.605L111.134 108.715L111.016 108.925L110.778 108.964L94.3556 111.665L86.0679 118.045L87.632 135.633L87.6438 135.765L87.5884 135.886L85.6373 140.135L90.1861 143.59L94.9231 136.35L95.0537 136.15L95.291 136.126L100.903 135.556L110.833 122.831Z" className="fill-primary" stroke="white"/>
            <path d="M713.091 179.268L713.028 179.104L713.082 178.937L714.755 173.765L711.628 163.705L706.739 164.746L702.348 172.824L702.272 172.962L702.132 173.032L696.551 175.816L690.055 184.921L694.352 192.715L697.704 195.266L710.156 195.042L710.278 188.899L710.284 188.603L710.546 188.466L715.581 185.832L713.091 179.268Z" className="fill-primary" stroke="white"/>
            <path d="M166.813 108.412L166.77 108.384L166.734 108.347L161.167 102.679L153.792 101.883L135.745 109.162L140.487 112.189L140.888 112.444L140.653 112.857L137.13 119.044L145.682 121.446L157.439 119.929L167.65 116.117L170.165 110.659L166.813 108.412Z" className="fill-primary" stroke="white"/>
            <path d="M443.271 161.127L443.264 161.126L443.257 161.124L431.583 158.465L434.308 160.979L434.439 161.099L434.464 161.275L436.137 172.89L448.971 176.101L456.209 175.553L455.469 163.536L443.271 161.127Z" className="fill-primary" stroke="white"/>
            <path d="M857.686 494.976L857.687 494.984L857.687 494.992L857.897 497.057L870.881 494.714L875.656 484.721L870.339 478.889L856.669 487.371L857.686 494.976Z" className="fill-secondary" stroke="white"/>
            <path d="M630.76 138.067L630.664 138.055L630.58 138.009L619.592 131.976L619.291 131.811L619.337 131.471L620.089 125.875L616.387 123.54L612.285 134.98L615.28 144.638L615.41 145.057L615.011 145.24L610.458 147.33L611.589 154.407L611.652 154.798L611.285 154.948L601.886 158.798L599.145 162.584L598.622 173.909L598.612 174.118L598.455 174.258L593.558 178.635L591.6 190.762L591.577 190.904L591.483 191.012L585.424 197.923L586.292 202.191L596.673 195.932L596.838 195.833L597.026 195.87L603.116 197.053L603.261 197.081L603.368 197.184L609.591 203.217L609.713 203.335L609.737 203.504L610.461 208.464L627.982 206.392L628.069 206.381L628.154 206.401L635.105 208.012L642.212 203.739L642.222 203.734L642.231 203.728L648.041 200.568L647.398 189.885L647.389 189.725L647.473 189.59L652.606 181.359L659.094 162.224L653.245 157.443L643.715 155.926L643.253 155.852L643.296 155.386L644.079 146.868L641.185 139.332L630.76 138.067Z" className="fill-primary" stroke="white"/>
            <path d="M50.5881 177.994L58.1453 176.833L51.5066 168.42L42.6423 166.469L34.1839 167.914L34.7829 176.156L50.5881 177.994Z" className="fill-primary" stroke="white"/>
            <path d="M874.141 377.86L874.43 377.686L874.7 377.889L877.616 380.094L880.749 372.128L879.516 368.624L870.582 371.548L870.449 371.591L870.313 371.559L862.766 369.785L862.51 369.725L862.415 369.479L860.303 364.04L854.065 364.709L853.978 364.718L853.894 364.698L833.463 359.761V364.324L838.423 373.641L848.573 373.412L848.786 373.407L848.937 373.558L861.104 385.702L874.141 377.86Z" className="fill-secondary" stroke="white"/>
            <path d="M753.951 566.738L753.611 566.659L753.568 566.313L752.862 560.639L739.294 567.712L737.39 570.014L749.893 583.26L758.887 589.599L768.636 578.767L760.398 568.236L753.951 566.738Z" className="fill-secondary" stroke="white"/>
            <path d="M726.195 140.471L726.251 140.319L726.386 140.229L736.974 133.162L727.597 117.781L714.013 105.29L713.991 105.27L713.972 105.248L707.144 97.3042L689.689 100.563L689.638 100.573L689.587 100.572L683.952 100.462L682.695 111.352L685.157 117.025L685.238 117.211L685.166 117.401L682.062 125.606L685.816 133.934L685.843 133.994L685.854 134.06L688.467 150.403L690.691 151.868L697.89 152.442L710.118 149.152L710.287 149.107L710.448 149.177L715.349 151.311L724.659 144.587L726.195 140.471Z" className="fill-primary" stroke="white"/>
            <path d="M134.392 109.482L134.278 109.409L134.216 109.289L130.131 101.305L124.576 101.737L117.009 110.332L116.137 120.685L119.721 121.099L132.166 120.63L136.03 118.959L139.551 112.775L134.392 109.482Z" className="fill-primary" stroke="white"/>
            <path d="M218.633 119.564L218.442 119.47L218.377 119.268L216.854 114.502L210.725 111.485L203.922 113.298L206.869 120.099L206.964 120.319L206.852 120.532L204.906 124.214L216.008 126.75L224.486 122.436L218.633 119.564Z" className="fill-primary" stroke="white"/>
            <path d="M936.588 332.129L936.65 332.031L936.747 331.97L946.984 325.567L942.563 314.392L938.329 311.64L938.131 311.511L938.104 311.276L936.834 299.929L935.867 300.008L918.13 301.873L914.96 307.646L921.675 317.685L922.205 318.478L921.251 318.463L914.192 318.354L915.528 325.081L915.575 325.319L915.418 325.503L911.305 330.326L911.434 336.05L911.439 336.31L911.23 336.464L905.883 340.393L912.129 344.078H917.998L923.543 338.202L923.733 338.001L924.005 338.055L931.874 339.643L936.588 332.129Z" className="fill-secondary" stroke="white"/>
            <path d="M163.09 85.3123L162.97 85.3047L162.867 85.2436L152.735 79.2457L145.86 92.7608L153.952 100.896L161.454 101.705L161.632 101.724L161.757 101.852L166.919 107.107L171.331 96.3754L168.296 85.6444L163.09 85.3123Z" className="fill-primary" stroke="white"/>
            <path d="M818.081 41.184L817.877 41.2159L817.709 41.0936L810.62 35.9111L800.84 38.4882L800.647 39.696L803.649 48.0923L803.674 48.1626L803.677 48.2373L804.732 70.8493L806.807 75.254L806.957 75.572L806.708 75.8206L801.82 80.7085L807.423 81.7476L809.572 71.5783L809.616 71.3731L809.792 71.2603L815.352 67.7117L815.575 67.5697L815.818 67.6733L830.32 73.8609L835.952 71.5734L836.149 71.4937L836.342 71.5793L847.098 76.3268L856.523 70.897L854.76 67.5373L854.645 67.317L854.751 67.0919L857.715 60.8007L842.942 53.7987L842.915 53.7858L842.889 53.7698L829.387 45.2531L829.287 45.1904L829.226 45.09L826.09 39.9342L818.081 41.184Z" className="fill-primary" stroke="white"/>
            <path d="M908.969 383.521L913.823 378.126L917.987 369.406L918.772 357.356L917.754 345.076H911.992H911.856L911.738 345.006L904.723 340.866L904.07 340.481L904.681 340.033L909.366 336.591L899.315 336.792L886.815 345.659L886.943 353.202L886.945 353.314L886.9 353.415L880.366 368.011L883.415 375.844L894.098 383.779L907.239 380.524L907.653 380.421L907.819 380.814L908.969 383.521Z" className="fill-secondary" stroke="white"/>
            <path d="M159.982 193.552L160.007 193.404L160.111 193.294L165.596 187.481L163.303 180.794L148.831 181.709L141.006 185.836L137.906 192.993L137.063 195.361L143.268 206.007L148.109 202.005L148.315 201.836L148.571 201.912L154.117 203.564L158.087 204.503L159.982 193.552Z" className="fill-primary" stroke="white"/>
            <path d="M640.41 249.371L640.512 249.326L640.625 249.328L667.949 249.915L671.531 246.382L673.687 236.407L668.722 232.081L661.963 230.963L656.452 236.346L656.296 236.498L656.078 236.487L633.971 235.423L633.827 235.416L633.709 235.334L624.802 229.13L614.825 227.57L613.855 234.291L616.68 243.355L621.823 246.687L622.056 246.839L622.051 247.116L621.93 253.539L633.338 252.531L640.41 249.371Z" className="fill-primary" stroke="white"/>
            <path d="M251.58 91.3322L251.574 91.1142L251.73 90.9618L257.916 84.9224L255.42 85.0761L255.25 85.0866L255.109 84.9914L244.438 77.7819L238.587 79.228L230.424 88.7212L229.02 93.8566L229.545 103.002L229.56 103.278L229.334 103.438L221.724 108.832L217.817 114.238L219.264 118.763L225.823 121.981L225.945 122.041L226.02 122.154L229.301 127.115L237.029 126.558L244.655 118.836L244.176 116.374L244.115 116.064L244.37 115.876L254.638 108.307L251.886 102.049L251.847 101.958L251.844 101.86L251.58 91.3322Z" className="fill-primary" stroke="white"/>
            <path d="M957.663 38.2159L957.483 38.2642L957.315 38.1826L936.643 28.1283L929.64 32.3354L933.728 38.6438L933.834 38.8079L933.801 39.0005L933.028 43.4872L937.485 56.3717L937.486 56.3746L939.807 62.8268L951.434 69.714L951.476 69.7389L951.512 69.7714L959.323 76.7506L959.528 76.9338L959.483 77.205L958.354 84.04L962.886 86.0643L962.211 83.3972L962.169 83.2318L962.237 83.0754L971.901 60.8366L971.913 60.8093L971.928 60.7836L979.57 47.7047L975.932 42.884L975.856 42.7833L975.837 42.6586L974.588 34.51L968.223 35.385L957.663 38.2159Z" className="fill-primary" stroke="white"/>
            <path d="M61.524 202.59L58.1374 203.469L57.5619 203.618L57.5135 203.025L56.6122 191.979L49.7228 189.23L39.9035 195.384L36.4382 206.85L40.2488 215.022L44.432 216.838L49.2275 213.004L49.433 212.839L49.6848 212.916L56.7861 215.069L60.4477 210.815L61.524 202.59Z" className="fill-primary" stroke="white"/>
            <path d="M907.85 256.337L907.933 256.422L907.969 256.534L910.801 265.336L919.203 259.153L925.409 252.337L926.439 239.45L918.706 229.316L914.104 227.801L908.732 232.82L911.143 239.967L911.253 240.293L910.988 240.512L900.685 249.037L907.85 256.337Z" className="fill-secondary" stroke="white"/>
            <path d="M922.916 120.63L923.217 120.67L923.321 120.956L925.916 128.133L933.971 124.425L935.856 119.372L936.247 106.688L934.528 103.287L924.75 98.6347L920.404 101.631L915.42 107.227L916.176 119.738L922.916 120.63Z" className="fill-secondary" stroke="white"/>
            <path d="M746.434 595.924L746.556 595.493L746.998 595.567L753.707 596.698L758.26 590.381L749.274 584.048L749.233 584.019L749.199 583.983L737.336 571.416L737.613 574.808L738.797 579.859L738.896 580.282L738.491 580.439L726.408 585.133L722.643 588.845L722.514 588.972L722.335 588.988L716.881 589.459L724.411 591.557L724.435 591.563L724.459 591.572L732.666 594.766L732.795 594.816L732.879 594.925L740.275 604.432H744.021L746.434 595.924Z" className="fill-secondary" stroke="white"/>
            <path d="M675.065 399.816L675.795 405.79L675.821 406.001L675.686 406.167L661.919 423.082L661.871 423.141L661.808 423.183L655.875 427.13L657.12 432.471L657.228 432.933L656.771 433.065L649.09 435.275L644.418 440.146L644.233 440.338L643.97 440.292L636.855 439.044L629.782 448.266L638.793 446.408L639.002 446.365L639.177 446.485L644.205 449.941L651.189 449.71L651.34 449.705L651.468 449.784L656.325 452.789L661.609 448.405L661.789 448.256L662.018 448.298L679.492 451.492L679.6 451.512L679.689 451.574L688.652 457.842L693.609 456.933L693.627 456.93L693.646 456.928L701.213 456.112L711.443 449.9L711.645 449.778L711.868 449.856L724.709 454.351L724.743 454.363L724.775 454.379L735.542 459.992L739.44 455.42L741.398 446.787L741.521 446.244L742.047 446.424L746.008 447.777L748.792 433.871L747.083 421.891L747.06 421.725L747.14 421.579L750.846 414.837L750.896 414.746L750.978 414.682L756.935 410.069L757.112 409.932L757.331 409.972L764.457 411.264L752.963 402.342L752.942 402.325L752.922 402.306L742.149 391.858L733.684 389.065L724.659 387.312L708.455 389.313L708.385 389.322L708.316 389.311L696.452 387.447L687.707 392.848L683.799 399.136L683.661 399.357L683.401 399.371L675.065 399.816Z" className="fill-primary" stroke="white"/>
            <path d="M952.543 300.073L952.801 300.094L952.933 300.316L956.147 305.714L961.35 307.396L959.695 300.211L959.663 300.074L959.708 299.941L962.62 291.188L962.667 291.047L962.782 290.955L967.572 287.125L966.561 282.303L953.726 286.731L953.49 286.813L953.283 286.673L948.058 283.143L940.476 285.586L939.751 288.395L939.748 288.405L939.744 288.416L936.518 298.949L937.24 298.891L937.28 298.888L937.319 298.891L952.543 300.073Z" className="fill-secondary" stroke="white"/>
            <path d="M81.7365 91.4613L81.7239 91.2219L81.9026 91.0622L88.7074 84.9817L83.4648 82.9448L58.5067 82.8281L51.1119 91.6383L50.9469 91.8348L50.6911 91.8154L42.8809 91.2241L42.617 91.2041L42.4851 90.9746L40.0957 86.8163L31.6105 85.8274L26.5884 100.389L30.368 96.1873L30.5622 95.9714L30.8459 96.0331L40.5865 98.1501L50.8558 98.0326L50.9761 98.0312L51.0839 98.0847L55.7147 100.383L66.8167 98.9828L67.0232 98.9567L67.1873 99.085L73.2756 103.845L82.1177 98.7263L81.7365 91.4613Z" className="fill-primary" stroke="white"/>
            <path d="M601.683 279.452L601.563 279.283L601.598 279.078L602.577 273.393L590.334 270.573L584.774 272.422L584.416 272.541L584.204 272.229L579.778 265.748L575.703 269.817L575.398 270.121L575.052 269.864L568.4 264.93L562.483 271.455L562.284 271.674L561.997 271.606L555.927 270.167L551.852 280.179L551.827 280.24L551.787 280.293L546.757 286.917L546.668 287.035L546.529 287.085L538.321 290.043L538.204 290.085L538.081 290.067L532.055 289.217L535.4 292.462L541.233 291.329L541.512 291.275L541.701 291.486L546.236 296.552L549.885 295.139L550.128 295.045L550.344 295.19L557.172 299.766L567.698 303.293L567.792 303.325L567.867 303.389L580.759 314.556L589.369 317.121L603.509 319.837L603.683 319.87L603.797 320.005L612.352 330.16L615.811 327.39L615.97 327.262L616.174 327.283L624.243 328.096L633.195 323.868L634.367 320.516L628.123 311.18L620.89 309.218L620.743 309.178L620.644 309.063L613.363 300.664L613.306 300.599L613.275 300.518L609.193 290.048L601.683 279.452Z" className="fill-primary" stroke="white"/>
            <path d="M163.748 84.3526L168.711 84.6691L169.064 84.6916L169.16 85.0321L172.338 96.2698L172.385 96.4361L172.319 96.596L167.708 107.81L170.979 110.002L186.564 112.109L200.532 110.974L199.599 105.242L194.514 102.402L194.292 102.278L194.262 102.025L193.399 94.8649L186.244 96.1876L186.146 96.2058L186.048 96.1847L178.37 94.5288L177.884 94.424L177.986 93.9374L180.369 82.5814L180.395 82.4564L180.478 82.3593L184.317 77.8644L184.601 77.5319L184.969 77.7692L198.242 86.3416L201.493 86.7289L200.117 69.2971L185.26 67.6946L174.092 70.395L174.061 70.4024L174.03 70.4059L168.923 70.9762L166.355 73.8178L163.748 84.3526Z" className="fill-primary" stroke="white"/>
            <path d="M869.797 435.765L869.747 435.711L869.714 435.646L866.225 428.601L853.028 423.323L848.927 438.871L854.688 440.623L854.919 440.694L855.007 440.919L856.604 444.979H863.947L870.181 436.177L869.797 435.765Z" className="fill-secondary" stroke="white"/>
            <path d="M908.805 384.94L908.483 384.932L908.358 384.636L907.068 381.598L894.111 384.808L893.882 384.864L893.693 384.724L882.705 376.562L882.59 376.476L882.537 376.342L881.359 373.313L878.338 380.993L878.081 390.755L883.971 397.047L890.38 400.319L890.501 400.381L890.574 400.496L896.568 409.928L903.409 412.22L904.929 409.311L900.192 402.104L900.054 401.893L900.141 401.657L902.391 395.506L902.538 395.105L902.957 395.188L910.283 396.63L912.706 385.043L908.805 384.94Z" className="fill-secondary" stroke="white"/>
            <path d="M110.173 134.659L110.329 134.564L110.509 134.592L119.283 135.955L121.293 131.941L119.282 122.056L115.677 121.64L111.547 123.542L101.873 135.94L104.638 138.031L110.173 134.659Z" className="fill-primary" stroke="white"/>
            <path d="M79.4753 137.77L79.4206 137.739L79.3751 137.696L66.3509 125.353L59.0336 122.944L48.2148 124.967L48.5785 129.958L48.5789 129.963L50.2607 150.307L63.6541 149.974L64.1315 146.027L64.1824 145.606L64.6058 145.587L86.0142 144.643L89.1186 144.036L84.7399 140.71L79.4753 137.77Z" className="fill-primary" stroke="white"/>
            <path d="M916.463 292.735L916.288 292.827L916.097 292.774L904.209 289.461L899.252 301.127L917.791 300.901L935.442 299.045L938.613 288.693L926.915 287.248L916.463 292.735Z" className="fill-secondary" stroke="white"/>
            <path d="M147.426 159.348L147.199 159.138L147.285 158.841L148.817 153.578L136.305 153.797L134.255 158.214L137.227 172.992L148.828 180.709L163.52 179.78L168.646 177.271L166.424 165.909L155.888 166.93L155.665 166.952L155.501 166.8L147.426 159.348Z" className="fill-primary" stroke="white"/>
            <path d="M869.139 71.9044L869.211 72.0289L869.206 72.1726L869.081 75.7417L876.158 82.8709L883.795 87.0535L888.154 75.368L885.063 68.5773L884.979 68.3918L885.048 68.2001L887.661 60.9712L879.355 57.7581L869.269 58.567L863.552 62.2622L869.139 71.9044Z" className="fill-primary" stroke="white"/>
            <path d="M293.576 211.323L293.508 211.143L293.577 210.964L297.989 199.601L295.377 195.293L294.669 199.633L294.622 199.919L294.35 200.021L283.23 204.161L283.144 204.193L283.051 204.193L269.064 204.077L266.556 209.006L266.444 209.226L266.202 209.27L254.685 211.4L254.465 211.44L254.288 211.304L250.974 208.751L248.113 215.907L266.732 221.415L272.768 221.884L272.965 221.899L273.098 222.045L280.079 229.668L292.019 228.533L296.932 220.09L293.576 211.323Z" className="fill-primary" stroke="white"/>
            <path d="M146.91 135.037L146.984 135.243L146.882 135.437L145.144 138.751L148.263 140.145L161.437 139.92L157.171 120.973L145.979 122.418L143.935 126.716L146.91 135.037Z" className="fill-primary" stroke="white"/>
            <path d="M792.764 471.047L792.591 471.017L792.475 470.885L785.061 462.433L770.999 470.13L771.507 477.619L771.524 477.881L771.319 478.044L765.2 482.904L806.634 485.196L820.538 485.305L815.522 480.007L815.228 479.697L815.488 479.358L818.154 475.894L812.018 468.692L807.371 473.391L807.187 473.577L806.929 473.531L792.764 471.047Z" className="fill-secondary" stroke="white"/>
            <path d="M760.798 567.303L760.97 567.343L761.079 567.482L769.411 578.135L777.426 573.323L785.118 563.519L775.097 556.467L761.587 556.931L753.815 560.18L754.519 565.844L760.798 567.303Z" className="fill-secondary" stroke="white"/>
            <path d="M171.624 127.603L171.453 127.513L171.388 127.331L167.72 117.16L158.144 120.734L162.502 140.093L165.234 141.757L165.273 141.781L165.307 141.811L169.875 145.893L171.947 146.726L174.538 138.9L174.92 129.348L171.624 127.603Z" className="fill-primary" stroke="white"/>
            <path d="M705.984 218.709L706.104 225.247L718.212 221.273L724.469 205.665L728.495 190.928L725.103 187.031L716.317 186.576L711.273 189.215L711.147 195.543L711.137 196.024L710.656 196.033L697.55 196.269L697.376 196.273L697.238 196.167L693.72 193.49L687.696 191.149L687.592 191.109L687.516 191.028L681.576 184.798L663.129 185.032L663.053 185.033L662.981 185.012L653.285 182.162L648.407 189.984L649.04 200.503L660.651 204.883L660.841 204.955L660.928 205.139L665.157 214.186L675.355 213.848L677.996 211.263L678.181 211.083L678.434 211.128L694.982 214.085L695.247 214.133L695.353 214.38L697.201 218.673L705.456 218.219L705.974 218.19L705.984 218.709Z" className="fill-primary" stroke="white"/>
            <path d="M499.437 208.448L499.203 208.481L499.029 208.321L494.201 203.89L484.153 200.042L468.696 198.069L465.258 201.361L462.702 210.037L464.781 214.297L469.377 216.027L475.515 211.685L475.661 211.581L475.841 211.594L480.606 211.949L480.767 211.961L480.89 212.065L489.495 219.28L489.638 219.4L489.667 219.584L490.16 222.667L502.875 230.319L511.197 232.607L516.19 229.373L521.689 218.375L521.774 218.205L521.952 218.134L530.18 214.867L533.717 210.823L532.453 211.576L532.287 211.675L532.098 211.636L521.639 209.507L521.611 209.501L521.583 209.492L512.692 206.572L499.437 208.448Z" className="fill-primary" stroke="white"/>
            <path d="M120.309 122.077L122.252 131.628L133.446 135.406L136.22 128.508L132.004 121.637L120.309 122.077Z" className="fill-primary" stroke="white"/>
            <path d="M945.667 221.085L945.513 220.934L945.516 220.718L945.639 213.486L926.474 215.852L925.763 221.462L935.611 229.567L935.71 229.648L935.758 229.767L938.273 236.036L938.333 236.187L938.294 236.344L936.573 243.205L936.537 243.347L936.431 243.447L928.671 250.789L938.774 248.862L938.961 248.826L939.124 248.923L943.33 251.429L950.732 250.061L957.404 245.969L957.467 245.931L957.538 245.912L962.787 244.526L959.329 237.023L959.258 236.869L959.296 236.704L961.414 227.349L951.817 226.904L951.628 226.895L951.492 226.763L945.667 221.085Z" className="fill-secondary" stroke="white"/>
            <path d="M735.515 500.745L735.421 500.742L735.335 500.706L730.31 498.573L713.921 511.257L713.792 525.85L715.759 533.117L715.782 533.201L715.775 533.287L715.087 541.999L724.985 532.183L725.067 532.103L725.175 532.066L730.324 530.296L751.867 521.493L754.6 516.927L742.767 507.617L742.571 507.463L742.576 507.214L742.699 500.966L735.515 500.745Z" className="fill-secondary" stroke="white"/>
            <path d="M848.753 145.747L848.567 146.069L848.204 145.982L828.621 141.293L829.481 151.723L833.92 158.955L833.92 158.956L837.413 164.641L842.509 162.798L851.064 155.733L853.079 149.207L850.142 143.353L848.753 145.747Z" className="fill-secondary" stroke="white"/>
            <path d="M611.279 135.116L611.229 134.956L611.286 134.799L615.636 122.667L615.729 121.914L609.188 115.957L603.171 116.186L602.923 116.195L602.766 116.005L588.052 98.1614L578.489 96.2241L571.243 100.618L571.055 100.732L570.844 100.671L560.596 97.6945L555.639 104.737L555.555 104.857L555.418 104.913L550.368 106.954L559.063 123.635L567.885 130.261L582.056 124.801L582.404 124.667L582.632 124.962L587.89 131.776L604.182 144.807L609.866 146.5L614.194 144.514L611.279 135.116Z" className="fill-primary" stroke="white"/>
            <path d="M672.006 25.0437L671.907 24.9258L671.891 24.7722L671.129 17.4009L657.245 12.6206L639.801 25.067L635.956 31.5104L640.485 32.7557L640.816 32.8469L640.85 33.1894L641.605 40.9532L646.9 41.6132L647.346 41.6688L647.338 42.1175L647.209 50.0912L652.515 56.3352L652.548 56.3748L652.573 56.4205L654.482 59.9376L671.537 53.3411L672.952 43.458L673.002 43.1141L673.341 43.0403L677.987 42.0305L680.809 35.4538L672.006 25.0437Z" className="fill-primary" stroke="white"/>
            <path d="M811.53 467.765L810.508 454.066L803.836 450.354L791.616 450.124L786.83 455.525L785.707 461.656L793.11 470.095L806.843 472.503L811.53 467.765Z" className="fill-secondary" stroke="white"/>
            <path d="M870.164 477.818L870.164 477.818L870.176 477.812L879.126 472.585L879.004 472.497L863.65 461.357L856.659 461.469L845.419 477.561L856.173 486.5L870.164 477.818Z" className="fill-secondary" stroke="white"/>
            <path d="M450.925 182.057L450.685 181.996L450.588 181.769L448.567 177.029L435.571 173.777L435.245 173.696L435.197 173.364L433.501 161.592L430.025 158.386L426.774 165.164L424.987 175.775L430.804 179.618L430.886 179.672L430.942 179.754L439.047 191.669L446.353 193.73L459.763 193.845L461.607 190.652L458.633 184.009L450.925 182.057Z" className="fill-primary" stroke="white"/>
            <path d="M728.28 243.046L734.322 246.434L734.906 246.761L734.427 247.228L730.266 251.283L731.27 255.139L735.015 250.575L735.237 250.304L735.567 250.42L748.464 254.939L750.104 236.553L746.699 233.619L742.684 232.967L738.885 235.909L738.872 235.92L738.858 235.929L728.28 243.046Z" className="fill-primary" stroke="white"/>
            <path d="M870.876 436.927L864.839 445.449L869.221 450.595L869.553 450.985L869.154 451.308L857.839 460.45L863.802 460.355L863.969 460.353L864.104 460.45L879.376 471.531L883.627 469.967L883.767 469.916L883.912 469.95L890.532 471.487L890.63 471.51L890.711 471.569L899.294 477.772L906.325 467.301L903.048 463.941L892.475 454.847L892.289 454.686L892.302 454.441L892.681 447.327L884.849 443.715L876.187 442.543L876.008 442.518L875.885 442.385L870.876 436.927Z" className="fill-secondary" stroke="white"/>
            <path d="M49.404 188.247L49.4124 188.242L49.4209 188.237L54.1451 185.531L50.2909 178.966L34.4137 177.12L27.1405 181.111L23.7513 187.6L39.4351 194.494L49.404 188.247Z" className="fill-primary" stroke="white"/>
            <path d="M765.13 529.021L765.097 528.994L765.07 528.962L755.328 517.658L752.639 522.15L752.554 522.293L752.399 522.356L732.038 530.676L746.256 534.66L746.518 534.733L746.599 534.993L748.516 541.16L760.195 546.146L769.439 549.049L770.254 545.095L770.323 544.759L770.662 544.703L778.401 543.414L790.031 537.745L772.251 534.807L772.118 534.785L772.014 534.699L765.13 529.021Z" className="fill-secondary" stroke="white"/>
            <path d="M170.268 147.128L172.305 157.949L180.372 149.758L180.725 149.399L181.081 149.754L187.785 156.436L192.523 155.695L194.755 148.604L190.388 139.014L179.505 142.633L172.553 147.79L172.328 147.956L172.069 147.852L170.268 147.128Z" className="fill-primary" stroke="white"/>
            <path d="M832.463 359.127V359.073L832.475 359.02L833.853 352.759L813.173 361.715L812.954 361.81L812.743 361.7L803.939 357.121L778.156 352.184L761.016 355.316L758.943 359.348L759.859 371.154L759.881 371.437L759.649 371.601L753.295 376.096L753.096 376.236L752.863 376.167L748.349 374.822L740.652 381.013L734.659 388.335L742.572 390.946L742.681 390.982L742.763 391.061L753.599 401.57L765.957 411.163L766.54 404.389L766.557 404.189L766.709 404.056L772.931 398.614L773.08 398.484L773.278 398.491L779.74 398.722L789.537 392.419L789.66 392.34H789.807H802.516H802.568L802.619 392.35L808.676 393.617L816.83 389.753L814.895 385.106L814.716 384.674L815.135 384.466L820.903 381.603L824.942 369.029L825.009 368.819L825.209 368.728L830.033 366.514L832.463 364.234V359.127Z" className="fill-primary" stroke="white"/>
            <path d="M747.926 373.877L748.13 373.713L748.382 373.788L751.252 374.642L743.713 367.114L734.926 363.13L734.759 363.054L734.681 362.888L730.64 354.313L718.796 358.704L712.532 369.549L712.43 369.724L712.236 369.78L706.704 371.352L704.911 380.28L704.877 380.452L704.742 380.565L697.512 386.601L708.401 388.312L724.614 386.309L724.693 386.3L724.77 386.315L733.611 388.032L739.909 380.338L739.942 380.297L739.983 380.265L747.926 373.877Z" className="fill-primary" stroke="white"/>
            <path d="M837.771 458.384L837.816 458.175L837.998 458.063L845.963 453.163L848.163 449.23L836.39 443.075L828.19 446.738L816.85 455.222L816.673 455.354L816.456 455.312L811.537 454.371L812.536 467.752L818.927 475.254L824.93 472.237L825.101 472.151L825.286 472.201L842.605 476.901L843.035 473.636L836.657 464.331L836.535 464.154L836.58 463.943L837.771 458.384Z" className="fill-secondary" stroke="white"/>
            <path d="M328.804 137.539L328.719 137.351L328.791 137.158L330.92 131.452L327.785 128.755L314.6 130.847L318.165 136.474L318.325 136.727L318.178 136.988L312.352 147.279L312.257 147.448L312.073 147.508L304.59 149.96L306.043 154.936L306.09 155.094L306.033 155.249L303.389 162.448L310.738 167.317L310.899 167.424L310.946 167.612L312.346 173.184L312.734 173.377L313.676 168.119L313.696 168.007L313.763 167.914L318.522 161.311L314.11 157.588L313.933 157.438V157.206V149.754V149.449L314.204 149.309L321.087 145.76L321.188 145.708L321.302 145.705L332.335 145.373L328.804 137.539Z" className="fill-primary" stroke="white"/>
            <path d="M525.983 283.631L526.176 283.635L526.316 283.768L530.834 288.035L538.099 289.06L546.051 286.195L550.952 279.741L554.988 269.824L541.77 263.287L541.543 263.175L541.499 262.925L540.509 257.287L530.798 256.842L527.378 260.124L527.196 260.299L526.947 260.256L518.215 258.742L511.104 261.529L510.856 267.947L514.913 277.067L515.079 277.441L514.745 277.678L512.194 279.488L515.546 278.792L515.828 278.733L516.02 278.948L520.111 283.517L525.983 283.631Z" className="fill-primary" stroke="white"/>
            <path d="M401.069 174.46L400.868 174.37L400.799 174.161L396.512 161.276L390.556 159.465L384.792 158.206L374.575 163.341L372.54 173.001L373.533 175.219L373.67 175.526L373.438 175.769L365.969 183.573L376.705 194.08L379.404 195.944L388.835 193.894L394.626 187.684L398.299 179.129L398.391 178.915L398.614 178.848L405.935 176.634L401.069 174.46Z" className="fill-primary" stroke="white"/>
            <path d="M843.581 238.45L843.344 238.187L843.513 237.877L847.706 230.176L839.553 232.536L832.637 238.029L831.606 245.049L831.552 245.417L831.185 245.471L816.999 247.565L814.736 251.608L814.609 251.834L814.352 251.861L805.695 252.785L803.561 255.539L804.569 268.04L815.252 268.609L815.422 268.618L815.551 268.728L824.685 276.535L824.889 276.709L824.856 276.975L823.576 287.503L830.556 290.877L829.951 281.798L829.94 281.63L830.032 281.49L835.21 273.625L831.948 259.52L831.906 259.34L831.993 259.175L837.95 247.82L838.057 247.615L838.282 247.564L847.906 245.386L848.304 243.697L843.581 238.45Z" className="fill-primary" stroke="white"/>
            <path d="M490.183 172.22L490.126 172.11L490.128 171.986L490.257 163.18L488.004 160.462L478.312 166.386L478.124 166.502L477.911 166.44L472.197 164.771L472.197 164.785L472.07 172.786L481.1 178.55L481.219 178.626L481.281 178.753L487.755 192.093L494.852 203.133L499.529 207.425L512.665 205.565L512.781 205.549L512.892 205.585L521.597 208.445L527.711 202.202L527.209 194.141L514.816 185.027L501.734 184.676L501.582 184.672L501.459 184.585L493.78 179.143L493.68 179.072L493.624 178.963L490.183 172.22Z" className="fill-primary" stroke="white"/>
            <path d="M747.756 146.18L747.627 146.117L747.552 145.995L740.628 134.906L737.719 133.866L727.075 140.971L725.543 145.078L725.49 145.22L725.367 145.308L715.839 152.189L712.492 163.118L715.575 173.037L720.07 170.605L720.196 170.537L720.338 170.546L730.146 171.13L740.228 166.684L740.311 166.647L740.401 166.642L748.342 166.177L759.445 158.849L751.747 148.135L747.756 146.18Z" className="fill-primary" stroke="white"/>
            <path d="M724.549 186.002L722.1 180.476L721.844 179.9L722.463 179.782L736.76 177.062L736.836 177.047L736.913 177.056L755.239 179.263L758.938 172.333L748.401 167.177L740.547 167.637L730.436 172.096L730.325 172.145L730.205 172.138L720.418 171.555L715.69 174.112L714.084 179.078L716.554 185.587L724.549 186.002Z" className="fill-primary" stroke="white"/>
            <path d="M261.497 145.035L261.303 144.989L261.193 144.822L259.172 141.741L248.796 146.151L242.974 152.258L243.765 155.083L254.422 160.425L254.745 160.587L254.693 160.944L253.121 171.828L257.77 185.788L263.503 187.641L278.053 176.613L283.32 168.578L280.867 156.814L274.944 148.184L261.497 145.035Z" className="fill-primary" stroke="white"/>
            <path d="M291.968 180.031L291.835 180.08L291.697 180.053L278.546 177.493L264.184 188.379L269.119 203.078L282.968 203.193L293.729 199.186L294.608 193.799L296.869 178.197L291.968 180.031Z" className="fill-primary" stroke="white"/>
            <path d="M252.134 172.031L252.096 171.919L252.113 171.801L253.589 161.582L232.766 167.974L232.5 168.056L232.29 167.873L227.041 163.286L225.867 172.621L228.054 174.575L228.09 174.608L228.119 174.646L233.679 181.98L233.787 182.123L233.78 182.301L233.391 192.514L239.553 199.626L247.271 203.649L250.262 203.238L256.843 186.173L252.134 172.031Z" className="fill-primary" stroke="white"/>
            <path d="M421.363 270.813L421.333 271.111L421.057 271.226L408.167 276.586L412.421 285.754L417.536 291.885L422.838 288.8L430.211 283.252L431.893 275.391L431.95 275.127L432.202 275.029L440.836 271.69L442.096 267.082L436.607 265.447L436.156 265.313L436.263 264.854L437.457 259.735L426.627 258.734L422.391 260.356L421.363 270.813Z" className="fill-primary" stroke="white"/>
            <path d="M834.399 525.181L834.349 525.238L834.284 525.278L830.044 527.87L836.206 530.773L854.266 516.466L851.508 512.358L845.251 512.797L834.399 525.181Z" className="fill-secondary" stroke="white"/>
          </svg>
        </div>
      </div>
    </div>
  );
}

export default AreaOfActivity;
