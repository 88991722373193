import React, { useState } from "react";
import logo from '../assets/logo-white.png';
import { GiHamburgerMenu } from "react-icons/gi";

const Header = () => {

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="w-full bg-primary-transparent absolute z-50">
      <nav className="w-3/4 mx-auto py-6 flex justify-between items-center">
        <div>
          <a href={"../"} className="inline-block">
            <img loading="lazy" className="w-24 lg:w-32 mr-4 lg:mr-8" alt="Logo" src={logo}/>
          </a>
        </div>
        <div className="md:hidden relative">
          <button
            className="text-light focus:outline-none p-2"
            onClick={toggleMobileMenu}
          >
            <i className="fa-solid fa-bars text-xl"><GiHamburgerMenu /></i>
          </button>
        </div>
        <ul className={`md:flex space-x-4 hidden md:block items-center`}>
          <li>
            <a href='../' className="mx-2 lg:mx-4 text-light">HOME</a>
          </li>
          <li className="p-3">
            <a href='../sobre-nos' className="mx-2 lg:mx-4 text-light">SOBRE NÓS</a>
          </li>
        </ul>
      </nav>
      {isMobileMenuOpen && (
        <ul className="absolute bg-primary-transparent top-26 right-0 z-20 mobile-menu w-48 rounded-b-xl grid">
          <li className="p-3">
            <a href='../' className="mx-2 lg:mx-4 text-light">HOME</a>
          </li>
          <li className="p-3">
            <a href='../sobre-nos' className="mx-2 lg:mx-4 text-light">SOBRE NÓS</a>
          </li>
        </ul>
      )}
    </header>
  );
};

export default Header;
