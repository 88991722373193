import React from 'react';
import Gustavo from '../assets/Gustavo.png';
import Simao from '../assets/Simão.png';

function AboutUs() {
  return (
    <section className="">
      <div className="w-3/4 mx-auto py-16 text-primary text-2xl">
        <h2 className="text-center text-primary font-bold text-3xl xl:text-4xl">SOBRE NÓS</h2>
        
        <div className="flex flex-col items-center md:flex-row-reverse md:items-center mt-16">
          <div className="md:w-1/2 flex justify-center items-center h-full">
            <img loading="lazy" src={Simao} alt="Simão" className="rounded-full w-64 h-64 object-cover mb-8 md:mb-0" />
          </div>
          <div className="md:w-1/2 md:pl-8 text-lg text-left">
            <h2 className="text-primary font-bold text-3xl mb-4">Marcos A. Simão</h2>
            <p className="mt-4">Especialista em Gestão de Representações Comerciais</p>
            <p className="mt-4">Com uma carreira robusta que ultrapassa quatro décadas, Marcos A. Simão é um experiente profissional no setor de representação comercial. Formado em Gestão de Representações Comerciais, Marcos construiu uma trajetória sólida e bem-sucedida em empresas de renome, como SulFabril, Marisol e Grupo Kyly.</p>
            <p className="mt-4">Sua experiência vasta e diversificada o capacita a oferecer uma visão estratégica e uma abordagem prática, fundamental para o sucesso em negociações e gerenciamento de representações. Marcos é reconhecido pela sua habilidade em construir e manter relacionamentos comerciais duradouros, e por seu compromisso em superar as expectativas dos clientes e parceiros.</p>
            <p className="mt-4">Agora com a Aura Showroom traz sua vivencia para evoluir todo o ecossistema do varejo.</p>
          </div>
        </div>
        
        <div className="flex flex-col items-center md:flex-row md:items-center mt-16">
          <div className="md:w-1/2 flex justify-center items-center h-full">
            <img loading="lazy" src={Gustavo} alt="Gustavo" className="rounded-full w-64 h-64 object-cover mb-8 md:mb-0" />
          </div>
          <div className="md:w-1/2 md:pl-8 text-lg text-left">
            <h2 className="text-primary font-bold text-3xl mb-4">Gustavo de F. Lolli</h2>
            <p>Formado em Administração de Empresas - UNIBAVE;</p>
            <p>Pós Graduado em Gestão do Varejo Omnichannel - ESPM;</p>
            <p>Nascido em família de comerciantes e apaixonado por vendas;</p>
            <p>7 anos de experiência como Representante Comercial do Grupo Kyly;</p>
            <p>Presidente da CDL de Orleans Gestão 2017-2018;</p>
            <p>Gerente de Vendas do Grupo Kyly por 4 anos.</p>
            <p className="mt-4">
              Tive a oportunidade de conhecer os três lados do varejo: Comerciante, Representante e Indústria.
            </p>
            <p className="mt-4">
              Com a Aura Showroom, nosso propósito é evoluir o ecossistema do Varejo através de Inteligência Comercial.
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row mt-16 gap-4">
          <div className="relative w-full md:w-1/2 h-0 pb-[56.25%]">
            <iframe
              src="https://www.google.com/maps/embed?pb=!4v1725296691074!6m8!1m7!1spiCWh3oLdcETUWEkysgQhw!2m2!1d-27.59160547329994!2d-48.57847042015583!3f124.08717383654593!4f1.8758839166222998!5f0.7820865974627469"
              className="absolute inset-0 w-full h-full border-0"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Map 1"
            ></iframe>
          </div>
          <div className="relative w-full md:w-1/2 h-0 pb-[56.25%]">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3536.056511794371!2d-48.58080402485767!3d-27.59177752191815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x952737e8053d7eab%3A0x2f5bf8c2f29cc034!2sR.%20Santos%20Saraiva%2C%20469%20-%20Estreito%2C%20Florian%C3%B3polis%20-%20SC%2C%2088070-101!5e0!3m2!1spt-BR!2sbr!4v1725296813705!5m2!1spt-BR!2sbr"
              className="absolute inset-0 w-full h-full border-0"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Map 2"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
