import React from 'react';
import Logo from '../assets/logo-white.png';
import { FaInstagram, FaLinkedinIn, FaEnvelope, FaMapMarkerAlt  } from 'react-icons/fa';
import { TbBrandLinktree } from "react-icons/tb";

function Footer() {
  return (
    <footer className="bg-primary text-white py-12 pb-24">
      <div className="w-3/4 mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="text-center md:text-left">
          <a href={"../"} className="inline-block">
            <img loading="lazy" className="w-48 mx-auto mb-4" alt="Logo" src={Logo} />
          </a>
          <p className="text-lg"></p>
        </div>
        <div className="text-center md:text-left">
          <h2 className="text-xl font-bold mb-4">Links úteis</h2>
          <ul className="space-y-2">
            <li>
              <a
                href="https://linktr.ee/aurashowroom"
                className="flex items-center justify-center md:justify-start hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TbBrandLinktree className="mr-2 text-lg" /> Linktree
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/aurashowroomsc/"
                className="flex items-center justify-center md:justify-start hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram className="mr-2 text-lg" /> Instagram
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/aura-showroom"
                className="flex items-center justify-center md:justify-start hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn className="mr-2 text-lg" /> Linkedin
              </a>
            </li>
          </ul>
        </div>
        <div className="text-center md:text-left">
          <h2 className="text-xl font-bold mb-4">Contato</h2>
          <p className="text-lg flex items-center">
            <svg className="w-4 h-4 mr-2" viewBox="0 0 174 173" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M126.844 85.1463L142.713 132.75L152.879 92.0843C154.83 84.2803 149.396 80.0903 147.684 78.9923C145.973 77.8933 139.899 74.7023 133.619 79.7263L126.844 85.1463ZM13.4561 172.113C10.7821 172.113 8.10106 171.305 5.76506 169.676C0.838061 166.24 -1.19594 160.103 0.703064 154.406L44.8531 21.9573C49.2991 8.61828 61.2571 0.000274658 75.3161 0.000274658C89.3771 -0.000725342 101.335 8.61728 105.781 21.9573L120.092 64.8893L121.103 64.0813C132.037 55.3323 146.719 54.5663 158.505 62.1303C170.291 69.6933 175.713 83.3583 172.316 96.9433L156.653 159.597C155.19 165.447 150.159 169.532 144.133 169.764C138.103 169.99 132.779 166.308 130.872 160.588L86.7741 28.2923C84.2191 20.6303 77.3591 20.0343 75.3171 20.0353C73.2751 20.0353 66.4141 20.6303 63.8611 28.2923L26.7781 139.539L88.8011 89.4143C89.8641 88.5643 91.4481 89.0033 91.9211 90.2793L97.7971 106.121C98.0971 106.928 97.8451 107.837 97.1721 108.375L21.8431 169.146C19.3771 171.119 16.4211 172.113 13.4561 172.113Z" fill='white' />
            </svg>
            Aura Showroom
          </p>
          <p className="text-lg flex items-center">
            <FaMapMarkerAlt className="w-4 h-4 mr-2 text-lg" />
            R. Santos Saraiva, 469 - Estreito,
          </p>
          <p className="text-lg flex items-center">
            Florianópolis - SC, 88070-101
          </p>
          <p className="text-lg">
            <a
              href="mailto:escritorio@aurashowroom.com.br"
              className="flex items-center justify-center md:justify-start text-white hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaEnvelope className="mr-2 text-lg mt-1" /> gustavo@aurashowroom.com.br
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
